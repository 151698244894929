<app-header></app-header>
<div class="aboutus-section">
  <div class="container">


    <div class="aboutus">
      <div class="about-tr">
        <h2 class="aboutus-title">ПОЛИТИКА ЗА ЗАШТИТА НА ЛИЧНИТЕ ПОДАТОЦИ
          Сајтех Доо СКОПЈЕ</h2>
        <div class="aboutus-text">
          <p>


            <b>1. ИНФОРМАЦИИ ЗА ПРИВАТНОСТ</b><br>
            Сајтех ја почитува вашата приватност и посветени сме на тоа да ги заштитиме вашите лични податоци.
            Политиката за заштита на лични податоци (во делот: Политика) ги дефинира начините на собирање лични
            информации, целта за која ги собираме, безбедносните мерки што ги користиме за да ги заштитиме луѓето со кои
            ги споделуваме и правата за приватност.
            Оваа политика се применува на:
            <li>сите корисници на нашата веб-страница www.metricity.mk</li>
            <li>сите корисници на платформата Metricity-Запознај го својот клиент</li>
          </p>

          <p>
            <b>2. УПРАВУВАЊЕ СО ЛИЧНИТЕ ПОДАТОЦИ</b><br>
            Оваа политика се однесува на сите лични податоци кои се собираат и складираат од страна на Сајтех Доо
            Скопје, со ул. Митрополит Теодосиј Гологанов 54/1-4, Скопје – Центар, Центар.
            Како контролор на податоци, Сајтех е одговорен за обработка и чување на вашите лични податоци.
            Доколку имате било какви прашања во врска со користењето на оваа политика или за остварување на вашите права
            според оваа Политика, ве молиме контактирајте не во некоја од следните контакти:
            <li>info&#64;metricity.mk</li>
            <li>+389 76 445 302</li>
            <li>Сајтех ДОО Скопје, ул. „Рајко Жинзифов“ бр.18-1/2, Скопје – Центар, Центар.</li>
            Сајтех дејствува како процесор во однос на корисниците на услугите што ги нуди (на пример, платформата
            Metricity – Запознај го својот клиент).
          </p>
          <p>
            <b>3. ОСНОВНИ ТЕРМИНИ</b><br>
            Еве го објаснувањето на основните термини што ги користиме во нашата политика.
            Секоја дефиниција дефинирана подолу ја има својата цел во оваа Политика, која е дефинирана во одредено
            поглавје.
            <li>
              Личните податоци се сите информации што може да ги идентификува поединец (на пример, име, презиме, меил
              адреса, телефонски број, итн.).
            </li>
            <li>Обработка значи собирање, складирање, пристапување и други форми на користење на лични податоци.</li>
            <li>
              ЕЕП е европски економски простор, кој ги идентификува сите земји-членки на ЕУ, Исланд, Норвешка и
              Лихтенштајн.
            </li>
          </p>

          <p>
            <b>4. ОБРАБОТКА НА ЛИЧНИ ПОДАТОЦИ</b><br>
            Во Сајтех, вашите лични податоци ги обработуваме исклучиво врз основа на јасно поставени цели, на безбеден и
            транспарентен начин.
            Ние ги собираме вашите лични податоци кога ги доставувате (на пример, користејќи ја нашата вебстраница или
            користејќи ја платформата Metricity-Запознај го својот клиент).
            Ние исто така ги собираме вашите лични податоци користејќи ги колачињата на нашата веб страница. Можете да
            прочитате повеќе за користење колачиња во Поглавје 7 од оваа Политика.
          </p>
          <p>
            <b>4.1 Видови лични информации што ги собираме</b><br>
            Сајтех може да ги собере следниве податоци или тип на информации:
            <li>Основни лични податоци за склучување на Договор за користење на софтверската платформа (име, презиме,
              телефонски број, меил адреса, позиција во компанијата во која работите) </li>
            <li>Останати податоци за реализација на Договор за користење на софтверска платформа (Име, Презиме, Татково
              име, ЕМБГ, Датум и место на раѓање, Држава и место на раѓање, Адреса на живеење, Лична карта број, Орган
              на издавање, Датум на важност, Држава лична карта, Пасош број, Држава пасош, Датум на важност, Адреса за
              контакт, Телефон, Емаил, Работен Статус, Работодавец, Адреса на работодавецот, Дејност на работодавецот,
              Продукт, Канал на Дистрибуција) </li>
            <li>
              Информации за вашиот компјутер (IP адреса, тип на уред, тип на прелистувач)
            </li>
            Сајтех внимателно го штити принципот на минимален износ на податоци што се предвидени со закон, така што ги
            собираме само информациите што се потребни, релевантни и ограничени на она што е потребно за целите за кои
            се обработува. Целите за кои собираме лични податоци се дефинирани во Поглавје 4.3. од овие политики.
          </p>
          <p>
            <b>4.3 Цел на обработка</b><br>
            Сајтех ќе ги обработува вашите податоци само за специфични, експлицитни и легитимни цели. Се обврзуваме да
            не ги обработуваме вашите лични податоци на начин што е неспојлив со целите дефинирани во оваа Политика.
            Целите во кои можеме да ги користиме вашите лични информации се дефинирани подолу. Сајтех може да ги користи
            вашите лични податоци за една или повеќе идентификувани цели.
            Целите за кои ќе ги користиме вашите лични податоци се следниве:
            <li>
              За склучување на договор со Нарачател и регистрација во платформата Metricity-Запознај го својот клиент
              неопходни се следниве лични податоци: (име, презиме, телефонски број, меил адреса, позиција во компанијата
              во која работите)
            </li>
            <li>За исполнување на обврската од Договорот – Пребарување на Носители на Јавни Функции, Негативни Вести и
              Листи на Осудуваност неопходни се следниве лични податоци (име, презиме и земја) </li>
            <li>За исполнување на обврската од Договорот – Идентификација на клиентски профил, неопходни се следниве
              податоци (име, презиме, датум и место на раѓање, адреса на живеење, адреса за контакт, ембг, лична карта
              број, орган на издавање, датум на важност, држава лична карта, пасош број, држава пасош, датум на важност)
            </li>
            <li>
              За исполнување на обврската од Договорот – Проценка на ризик од перење пари и финансирање на тероризам,
              неопходни се следниве податоци (Име, Презиме, Татково име, Датум и место на раѓање, Држава и место на
              раѓање, Адреса на живеење, Адреса за контакт, Работен Статус, Работодавец, Адреса на работодавецот,
              Дејност на работодавецот, Продукт, Канал на Дистрибуција)
            </li>
            <li>
              За исполнување на обврските од Правилникот за Техничко Организационите Мерки за обезбедување на тајност и
              заштита на личните податоци – Мониторинг и Логирање, неопходни се следниве податоци (IP Адреса, Тип на
              Уред, Тип на пребарувач)
            </li>
          </p>
          <p>
            <b>4.4 Колку долго ги чуваме вашите лични податоци</b><br>
            Вашите лични податоци се чуваат во согласност со важечките закони и (i) само колку што е потребно за да се
            постигне целта за која се обработуваат податоците или (ii) за законски период за периодот што е потребен за
            извршување на договорот, вклучувајќи ги сите гарантни периоди и рокови во кои е можно да се утврдат било
            какви договорни побарувања (на пр. 30 дена од исполнувањето на договорните обврски).
            Личните информации добиени врз основа на Договорот ќе бидат засегнати за целото времетраење на Договорот или
            додека не се раскине Договорот. (за повеќе информации за откажување на согласност, видете Точка 9 од оваа
            Политика).
            Личните податоци за кои е истечен периодот на задржување (на пр., на пример раскинување на Договорот и сл.)
            Ќе бидат избришани, уништени или анонимизирани на таков начин што употребата на личните податоци повеќе не е
            возможна.
            Доколку ви требаат дополнителни информации за периодот на задржување на вашите лични податоци, ве молиме
            контактирајте не на било кој од контактите дефинирани во Точка 2 од оваа Политика.
          </p>
          <p>
            <b>5. ЗАШТИТА НА ВАШИТЕ ЛИЧНИ ПОДАТОЦИ</b><br>
            Со цел да ги заштитиме вашите лични податоци, Сајтех има усвоено соодветни технички и организациски мерки
            кои вклучуваат:
            <li>
              редовно и ефикасно ажурирање на софтверот и компјутерската опрема каде што ги чуваме вашите лични
              податоци,
            </li>
            <li>обезбедување на пристап до лични податоци,</li>
            <li>поддршка,</li>
            <li>едукација на вработените кои обработуваат лични податоци на работа,</li>
            <li>информирана и внимателна работа при изборот на лице кое ги обработува вашите лични податоци,</li>
            <li>
              прави надзор над вработените и другите лица кои обработуваат ваша лична информација, вклучувајќи ревизија,
              следење и преземање на соодветни мерки во случај на какви било безбедносни инциденти кои го спречуваат или
              ограничуваат настанувањето на оштетувањето на личните податоци.
            </li>
            Во Сајтех, ги заштитуваме вашите лични податоци од неовластена обработка и / или пристап, од неовластено
            губење, уништување или оштетување. Ги преземаме сите мерки според нашите технолошки способности (вклучувајќи
            ги и трошоците за спроведување на одредени мерки) и го проценуваме влијанието врз вашата приватност.
            Во случај на повреда на заштитата на личните податоци, Сајтех без одлагање ќе го извести надлежниот надзорен
            орган за секое такво прекршување.
            Во случај на сомневање дека е сторено кривично дело, Сајтех ќе поднесе извештај за прекршоци до полицијата и
            до надлежниот обвинител.
            Во случај на повреда на заштитата на податоците што може да претставува висок ризик за правата и слободите
            на поединците, Сајтех ќе ве извести без одлагање за таков настан.
          </p>
          <p>
            <b>6. ПОДОБРУВАЊЕ НА ЛИЧНИ ПОДАТОЦИ</b><br>
            Вашите лични податоци, кои се користат само за да се постигне целта за која сме собрани, може да им се
            доделат на одредени трети лица како што е дефинирано подолу и им е дозволено да ги пристапуваат и да ги
            разгледуваат. Сите корисници со кои споделуваме лични податоци може да обработуваат податоци само за целите
            за кои биле собрани. Исто така, од сите корисници се бара да се усогласат со применливите закони, како и
            одредбите од политиката за заштита на личните податоци.
            Ги персонализираме вашите лични податоци со: <br>
            1. Деловни партнери кои ни помагаат за ремаркетинг ние користиме Google AdWords и Google Analytics. <br>
            2. Други договорни партнери кои се грижат за потребите на Сајтех (сметководствени услуги, правни фирми
            итн.). <br>
            3. Партнерски компании кои ни помагаат во обезбедувањето на нашите услуги. <br>
            4. Кога законот од нас ги побарува (на пример, даночни органи, судови итн.). <br>
            Вашите лични податоци може да се пренесат на трети страни (дефинирани погоре) надвор од Европската економска
            област (ЕЕА), каде што овие информации можат да бидат обработени од нас и од трети лица. При секој трансфер
            надвор од Европската економска област, ќе преземеме дополнителни мерки за да ја осигуриме безбедноста на
            вашите лични податоци.
            Ваквите мерки главно се состојат од договори со трети страни за воспоставување на задолжителни прописи за
            заштита на личните податоци, проверка на постоење на одобрени механизми за сертификација кои ги исполнуваат
            нашите стандарди за заштита на личните податоци и склучување на соодветни договорни обврски со кои се
            уредува заштитата на личните податоци.
          </p>

          <p>
            <b>
              7. ПОЛИТИКА НА КОЛАЧИЊА
            </b><br>
            ШТО СЕ?<br>
            <p>
              Колачињата се мали текстуални датотеки што повеќето веб-сајтови ги чуваат на уреди што корисниците
              пристапуваат на Интернет за да идентификуваат поединечни уреди што ги користат корисниците при пристапот.
              Нивното складирање е под целосна контрола на прелистувачот на корисникот – кое може да го складира или
              оневозможи складирањето на колачиња на барањето. Колачињата не се штетни и секогаш се ограничени.
              Ние користиме колачиња за да обезбедиме добри веб услуги, подобро корисничко искуство и статистика за
              посетите. Интеракцијата на веб-корисниците и веб-сајтовите е побрза и полесна со помош на колачиња. Со
              нив,
              веб-сајтот се сеќава на можностите и искуствата на поединецот, заштедувајќи време и правење на
              прелистување
              поефикасно и пријатно. Колачињата не се штетни и временски ограничени.
            </p>
            ЗА ШТО СЕ КОРИСТАТ?<br>
            Колачињата се од суштинско значење за обезбедување на лесни онлајн услуги. Интеракцијата на веб-корисниците
            и веб-сајтовите е побрза и полесна со помош на колачиња. Со нив, веб-сајтот се сеќава на можностите и
            искуствата на поединецот, заштедувајќи време и правење на прелистување поефикасно и побрзо.
            Некои конкретни примери за користење колачиња:
            <li>
              За подобро корисничко искуство, ние ги прилагодуваме посетителите да ја гледаат содржината со минати
              посети,
            </li>
            <li>
              за да го препознае вашиот уред (компјутери, таблети, мобилни телефони), овозможувајќи ви да го прилагодите
              приказот на содржината на Вашиот уред,
            </li>
            <li>
              за следење на посети кои ви овозможуваат да ја проверите ефикасноста на рендерирање на содржина и
              релевантноста за вашите реклами и постојано да го подобрувате вашиот веб-сајт,
            </li>
            ЛИСТА НА КОЛАЧИ: <br>
            1. ПОТРЕБНИ КОЛАЧИЊА <br>
            Овие колачиња ви дозволуваат да ги користите потребните компоненти за правилно функционирање на
            веб-страницата. Без овие колачиња, услугите што сакате да ги користите на веб-страница не може да
            функционираат правилно.
            2. КОЛАЧИЊА СО ИСКУСТВО <br>
            Овие колачиња собираат информации за тоа како корисниците се однесуваат на веб-страница, со цел да го
            подобрат искуството на веб страната (на пр., Која содржина најмногу ја посетувате на нашата веб-страница).
            Овие колачиња не собираат информации што би можеле да го идентификуваат корисникот. Само проверуваат дали
            користењето на страницата е пријатно искуство.
            3. ФУНКЦИОНАЛНИ КОЛАЧИЊА <br>
            Овие колачиња овозможуваат на сајтот да се запаметат некои од вашите избрани опции (како што се јазикот,
            регион) и нуди напредни, персонализирани функции. Овие колачиња можат да овозможат следење на вашите
            активности на веб-страницата.
            4. РЕКЛАМНИ КОЛАЧИЊА <br>
            Овие колачиња најчесто се користат од рекламните компании и социјалните мрежи (трети лица) за да покажат
            повеќе насочени реклами, да ограничат повторувања на рекламите или да ја измерат ефективноста на нивните
            кампањи. Овие колачиња можат да ја следат ашата интернет активност.
            5. НАДЗОРНИ КОЛАЧИЊА <br>
            Вие решавате сами дали ќе користите колачиња. Секогаш можете да ги отстраните колачињата за да ја отстраните
            вашата препознатливост на Интернет. Слично на тоа, можете да ги поставите повеќето прелистувачи со тоа да не
            сочувувате колачиња.
            Препорачуваме да ги разгледувате информациите за опциите на секој прелистувач.
            <li>Internet Explorer</li>
            <li>Chrome</li>
            <li>Firefox</li>
            На нашиот сајт ние користиме колачиња кои ни овозможуваат да го подобриме и оптимизираме нашиот веб-сајт,
            што ви дава подобро корисничко искуство.
            Колачињата се едноставни текстуални датотеки коишто чуваат некои веб-страници на компјутерот преку
            прелистувачот и складираат некои од не-личните информации.
            Користењето колачиња ни овозможува да ги прилагодуваме индивидуалните онлајн содржини за да го направиме
            корисникот попривлечен. Покрај тоа, ние исто така спроведуваме анализа на користењето на веб-страницата,
            која ни овозможува да ја подобриме нашата веб-страница и да ја направиме пријатна за нашите корисници.
            Некои колачиња се неопходни, бидејќи без нив веб-страницата не може правилно да функционира, можете да ги
            одбиете сите други колачиња. Ние ги користиме потребните колачиња за складирање на статистики за користење
            на нашата веб-страница и за складирање на информациите потребни за пополнување на формуларите за контакт што
            се дадени на нашата веб страница.
            Со потребната помош, ние исто така користиме други колачиња на нашата веб-страница, кои ни овозможуваат
            подобро да ги запознаеме нашите корисници и да обезбедиме прилагодено рекламирање врз основа на собраните
            податоци. Отфрлањето на употребата на колачиња може да предизвика функционалност на одредена содржина или
            способности на веб-страницата (ова вклучува приспособување на веб-страницата за да стане интересно и
            привлечно за корисникот).
            За да ги видите сите колачиња, видете ја табелата на крајот од ова поглавје.
            На нашата веб-страница, покрај нашите сопствени колачиња, ние исто така користиме провајдери на колачиња
            (колачиња од трети страни): Google Analytics, Google AdWords, Екстензија за реклама за Google Analytics
            (сите колачиња се управуваат од Google Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, ZDA),
            Hotjar, ActiveCampaign ( LLC, North Dearborn Street, 5th Floor, Chicago,
            IL 60602) AdRoll (AdRoll Inc. 972 Mission Street, San Francisco, CA 94103), Facebook Custom Audience и
            Facebook ремаркетинг (Facebook Inc, 1 Hacker Way, Menlo Park, CA 9420).
            Можете да ги отфрлите сите трети лица на колачиња наведени погоре, или да ги избришете во вашиот
            прелистувач.
            <li>За да избришете колаче на Google Analytics, поставете го прелистувачот да ги одбива колачињата со “pum”
            </li>
            <li>
              За да избришете колаче на Google AdWords, поставете го вашиот прелистувач за да ги отфрлите колачињата со
              доменот “www.metricity.mk”
            </li>
            <li>
              За да ја избришете екстензијата за рекламна реклама за колачиња на Google Analytics, поставете го вашиот
              интернет пребарувач да ги отфрли колачињата со доменот “.
            </li>
            <li>
              За да избришете колаче Hotjar, поставете го вашиот прелистувач за да ги отфрлите колачињата со
              “_hjIncludedInSample”
            </li>
            <li>
              За да го избришете колачето ActiveCampaign, поставете го прелистувачот да ги одбива колачињата со доменот
              ˝_form_˝
            </li>
            <li>За да го избришете колачето AdRoll, поставете го вашиот пребарувач за да ги отфрлите колачињата со
              “_te_”</li>
            Ги потсетуваме корисниците дека горенаведените провајдери можат да соберат одредени лични информации кои не
            се поврзани со собирањето податоци од Сајтех. Таквата посебна колекција на лични податоци не е опфатена со
            оваа Политика, но е дефинирана во политиката за приватност на секој обезбедувач на колачиња.
            Повеќе за заштита на колачињата од трети лица се достапни во политиката за приватност на кој било добавувач
            од трети лица:
            <li>Google</li>
            <li>Hotjar</li>
            <li>ActiveCampaign</li>
            <li>AdRoll</li>
            <li>Facebook</li>
            <li>LinkedIn</li>
            Сајтех користи одредени додатоци од други провајдери, вклучувајќи го и Фејсбук и Линкедин, на својата
            веб-страница. За оваа цел, двата провајдери користат колачиња кои можете да ги откажете во вашиот
            прелистувач. Политиките за користење на овие додатоци се дефинирани од безбедносните политики на секој
            давател на услуги и се достапни на линковите наведени погоре.
            Колачи на www.: <br>
            PHPSESSID <br>
            Колачето го поставува PHP, кој го содржи кодот на сесијата и трае до неговото завршување (се додека
            пребарувачот не се затвори). Потребно е за наоѓање локација. Не содржи лични податоци. <br>
            _ga _gat <br>
            Овие колачиња ја поставуваат Google Analytics за да ги најавуваат посетите на страницата. Некои траат до
            крајот на сесијата, некои повеќе. Тие не се задолжителни и не содржат никакви лични податоци.
            complianceCookie <br>
            Колаче кое ве информира за тоа како веб-сајтот користи колачиња. Тоа трае 14 дена и гарантира дали
            известувањето веќе не е прикажано во овој момент.

            <p>
              <b>8. ВЕБ ПОДАТОЦИ И ПРИСТАП ДО СОЦИЈАЛНИТЕ МРЕЖИ</b> <br>
              Во својата работа, Сајтех исто така користи социјални медиуми, како што се Фејсбук и Линкедин. Фејсбук и
              Линкедин работат во согласност со нивните услови за користење и политики кои ја дефинираат употребата на
              лични информации на нивните корисници. Ги потсетуваме корисниците дека се сами одговорни за сите изданија
              на
              социјалните медиуми.
              Сајтех не презема одговорност за активностите на социјалните мрежи.
            </p>
            <p>
              <b>9. ПРАВА НА ПОЕДИНЦИТЕ</b> <br>
              За обработка на вашите лични информации, следниве права се опишани подолу:
              <li>
                Пристап до личните податоци: Вие може да побарате од Сајтех пристап до личните податоци и обработката на
                информации (податоците кои ги обработува и кога).
              </li>
              <li>
                Корекција на лични податоци: Можете да побарате од Сајтех да ги поправи или да ги дополни нецелосните
                или
                неточните информации што ги обработуваме за вас.
              </li>
              <li>
                Ограничување на обработката на личните податоци: можете да побарате Сајтех за ограничување на
                обработката
                на личните податоци (на пример, во целост да ја потврди точноста на вашите лични информации.).
              </li>
              <li>
                Бришење на лични податоци: Можете да побарате од Сајтех да ги избрише вашите лични податоци (бришењето
                може да не ги избрише детално личните податоци, што се користат за правни потреба или од страна на
                договорен
                однос).
              </li>
              <li>
                Испис на лични податоци: можете да побарате од Сајтех дека вашите лични податоци ќе ги достават до вас,
                ние може да интервенираме во структурата, употребливоста и читливата форма.
              </li>
              <li>
                Одземање на дозвола: Имате право во секое време да ја повлече согласноста за користење на вашите лични
                информации кои ги собираме и процесот врз основа на ваша согласност. Согласноста може да се прекине на
                кој
                било начин наведени во Точка 2 од оваа Политика. Повлекувањето на дозволата нема негативни последици, но
                Сајтех можеби не е во можност да ви обезбеди одредени услуги поради вашето повлекување на податоци.
              </li>
              <li>
                Приговор на обработка на личните податоци: Имате право да приговарате на обработката на личните податоци
                за цели на директен маркетинг или за пренос на лични податоци на трети лица за цели на директен
                маркетинг.
                o Можете исто така да поднесете жалба кога вашите податоци се користат за директен маркетинг со
                користење на
                сопствени или индивидуални наддавање (“профилирање”). Можете да поднесете приговор на каков било начин
                дефиниран во Точка 2 од оваа Политика.
              </li>
              <li>
                Право на пренос на податоци: Имате право да имате пристап до личните информации што сте ги споделиле со
                нас. Податоците ќе бидат испорачани во структурирана, често користена и во читлива форма. Имате право да
                ги
                пренесете овие податоци на менаџерот со податоците по ваш избор. Имате право да пренесувате лични
                податоци
                директно на друг менаџер, доколку тоа е технички изводливо.
              </li>
              Можете да пристапите до сите права преку контактирање со нас преку кој било канал дефиниран во Точка 2 од
              оваа Политика. Исто така, овие контакти се достапни доколку ви се потребни дополнителни информации за
              вашите права.
              Имате право да поднесете жалба до овластено лице одговорно за заштита на личните податоци.
              Во Сајтех ние ја земаме предвид брзината и интегритетот на личните податоци што ги обработуваме. Ве молиме
              да известите што е можно поскоро било какви промени во вашите лични податоци на info&#64;metricity.mk. Колку
              побрзо ја доставите промената до нас, толку побрзо ќе обезбедиме корекција или надополнување на вашите
              лични податоци.
              Сајтех го задржува правото да бара одредени лични податоци (како име, презиме, меил адреса) заради
              идентификување на поединецот, во случај на остварување на правата од ова точка. <br>
              Сајтех го задржува правото да бара одредени лични податоци (како име, презиме, меил адреса) заради
              идентификување на поединецот, во случај на остварување на правата од ова точка.
            </p>
            <p>
              <b>10. ЗАВРШНИ ОДРЕДБИ</b> <br>
              Сајтех може да ја смени оваа политика. Во случај на промена ние ќе ве известиме однапред. Вие се
              согласувате дека се согласувате со новата верзија на оваа Политика, ако по новата верзија на оваа Политика
              стапи во сила, продолжувате да ја користите нашата веб-страница и другите услуги дефиниции во оваа
              Политика.
            </p>



        </div>
      </div>

    </div>
  </div>
</div>
<app-footer></app-footer>
