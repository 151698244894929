import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from "@app/services/language.service";

@Pipe({
  name: 'addLanguagePrefix',
})
export class AddLanguagePrefixPipe implements PipeTransform {


  constructor(private languageService: LanguageService) {

  }

  transform(value: any, args?: any): string {
    return `/${this.languageService.getCurrentLanguage()}${value}`
  }
}
