import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@app/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OrganisationLevelTwoType} from 'metricity-shared/src/interfaces/models/organisation-level-two-type';
import {OrganisationLevelThreeType} from 'metricity-shared/src/interfaces/models/organisation-level-three-type';
import {finalize, map} from 'rxjs/operators';
import {NotificationComponent} from '@app/shared/components/notification/notification.component';
import {SignupPrivacyPolicyComponent} from '@app/components/signup-privacy-policy/signup-privacy-policy.component';
import {SignupTermsPolicyComponent} from '@app/components/signup-terms-policy/signup-terms-policy.component';
import {TranslateService} from '@ngx-translate/core';
import {RxUtilsService} from '@app/services/rx-utils.service';
import {LanguageService} from '@app/services/language.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  organisationLevelOneTypes: Observable<{ value: number, label: string, children: OrganisationLevelTwoType[] }[]>;
  organisationLevelTwoTypes: Observable<{ value: number, label: string, children: OrganisationLevelThreeType[] }[]>;
  organisationLevelThreeTypes: Observable<{ value: number, label: string }[]>;

  organisationLevelTwoTypes$ = new BehaviorSubject<OrganisationLevelTwoType[]>(null);
  organisationLevelThreeTypes$ = new BehaviorSubject<OrganisationLevelThreeType[]>(null);

  managerFormGroup: FormGroup;
  organisationFormGroup: FormGroup;

  signingUp$ = new BehaviorSubject(false);

  acceptedTermsAndPrivacyPolicies = false;

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private languageService: LanguageService,
              private rxUtilsService: RxUtilsService) {
    this.managerFormGroup = new FormGroup({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      position: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required])
    });
    this.organisationFormGroup = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      organisationLevelOneTypeId: new FormControl(null, [Validators.required]),
      organisationLevelTwoTypeId: new FormControl(),
      organisationLevelThreeTypeId: new FormControl(),
      address: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      zip: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
    const clientTypesObservable = this.rxUtilsService.createSharedValueNotCached(of(true),
      () => this.authenticationService.getClientTypes(), '', null);
    this.organisationLevelOneTypes = clientTypesObservable.pipe(
      map(orgLevelOneTypes => orgLevelOneTypes.map(it => ({
        value: it.id,
        label: this.languageService.getCurrentLanguage() === 'de' ? it.levelOneTypeDe : this.languageService.getCurrentLanguage() === 'en' ? it.levelOneTypeEn : it.levelOneType,
        children: it.children
      })))
    );
    this.organisationLevelTwoTypes = this.organisationLevelTwoTypes$.asObservable().pipe(
      map(orgLevelTwoTypes => orgLevelTwoTypes?.map(it => ({
        value: it.id,
        label: this.languageService.getCurrentLanguage() === 'de' ? it.levelTwoTypeDe : this.languageService.getCurrentLanguage() === 'en' ? it.levelTwoTypeEn : it.levelTwoType,
        children: it.children
      })))
    );
    this.organisationLevelThreeTypes = this.organisationLevelThreeTypes$.asObservable().pipe(
      map(orgLevelThreeTypes => orgLevelThreeTypes?.map(it => ({
        value: it.id,
        label: this.languageService.getCurrentLanguage() === 'de' ? it.levelThreeTypeDe : this.languageService.getCurrentLanguage() === 'en' ? it.levelThreeTypeEn : it.levelThreeType
      })))
    );
  }

  signUp() {
    this.signingUp$.next(true);
    const organisationFormGroupValue = this.organisationFormGroup.value;
    organisationFormGroupValue['organisationLevelOneTypeId'] =
      organisationFormGroupValue['organisationLevelOneTypeId'] ? organisationFormGroupValue['organisationLevelOneTypeId'] : null;
    organisationFormGroupValue['organisationLevelTwoTypeId'] =
      organisationFormGroupValue['organisationLevelTwoTypeId'] ? organisationFormGroupValue['organisationLevelTwoTypeId'] : null;
    organisationFormGroupValue['organisationLevelThreeTypeId'] =
      organisationFormGroupValue['organisationLevelThreeTypeId'] ? organisationFormGroupValue['organisationLevelThreeTypeId'] : null;
    organisationFormGroupValue['acceptedTermsAndPrivacyPolicies'] = this.acceptedTermsAndPrivacyPolicies;
    this.authenticationService.signUpClientOrganisation(this.managerFormGroup.value, organisationFormGroupValue)
      .pipe(
        finalize(() => this.signingUp$.next(false))
      ).subscribe(() => {
      this.router.navigate(['../app/login'], {relativeTo: this.activatedRoute});
      this.snackBar.openFromComponent(NotificationComponent, {
        data: {
          message: this.translate.instant('signup:Успешна Регистрација. Ќе добиете електронска порака за наредни чекори.')
        },
        panelClass: 'success-snackbar',
        duration: 5000
      });
    }, () => {
      this.snackBar.openFromComponent(NotificationComponent, {
        data: {
          message: this.translate.instant('signup:Се јави грешка. Проверете ги податоците и обидете се повторно')
        },
        panelClass: 'danger-snackbar',
        duration: 5000
      });
    });
  }

  handleChangeLevelOne(event, organisationLevelOneTypes: { value: number, label: string, children: OrganisationLevelTwoType[] }[]) {
    const children = organisationLevelOneTypes.find((it) => it.value === event.value).children;
    this.organisationFormGroup.patchValue({
      organisationLevelTwoTypeId: null,
      organisationLevelThreeTypeId: null
    });
    if (children.length > 0) {
      this.organisationFormGroup.get('organisationLevelTwoTypeId').setValidators([Validators.required]);
    } else {
      this.organisationFormGroup.get('organisationLevelTwoTypeId').clearValidators();
    }
    this.organisationFormGroup.get('organisationLevelThreeTypeId').clearValidators();
    this.organisationLevelTwoTypes$.next(children && children.length > 0 ? children : null);
    this.organisationLevelThreeTypes$.next(null);
    this.organisationFormGroup.get('organisationLevelTwoTypeId').updateValueAndValidity();
    this.organisationFormGroup.get('organisationLevelTwoTypeId').markAsUntouched();
    this.organisationFormGroup.get('organisationLevelThreeTypeId').updateValueAndValidity();
    this.organisationFormGroup.get('organisationLevelThreeTypeId').markAsUntouched();
  }

  handleChangeLevelTwo(event, organisationLevelTwoTypes: { value: number, label: string, children: OrganisationLevelThreeType[] }[]) {
    const children = organisationLevelTwoTypes.find((it) => it.value === event.value).children;
    this.organisationFormGroup.patchValue({
      organisationLevelThreeTypeId: null
    });
    if (children.length > 0) {
      this.organisationFormGroup.get('organisationLevelThreeTypeId').setValidators([Validators.required]);
    } else {
      this.organisationFormGroup.get('organisationLevelThreeTypeId').clearValidators();
    }
    this.organisationLevelThreeTypes$.next(children && children.length > 0 ? children : null);
    this.organisationFormGroup.get('organisationLevelThreeTypeId').updateValueAndValidity();
    this.organisationFormGroup.get('organisationLevelThreeTypeId').markAsUntouched();
  }

  showTermsDialog() {
    this.dialog.open(SignupTermsPolicyComponent, {
      autoFocus: false,
      disableClose: true,
      height: '80%'
    });
  }

  showPrivacyDialog() {
    this.dialog.open(SignupPrivacyPolicyComponent, {
      autoFocus: false,
      disableClose: true,
      height: '80%'
    });
  }

  findLevelLabel(levels: { value: number, label: string }[], value: number) {
    const level = levels.find(it => it.value === value);
    return level ? level.label : '-';
  }

}


