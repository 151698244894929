<ng-template [ngIf]="type === 'raised-button'">
  <button mat-raised-button [matTooltip]="tooltip" [color]="color" [type]="buttonType ? buttonType : 'button'" *ngIf="!running && !disabled" (click)="click()">
    <fa-icon *ngIf="icon" [icon]="icon" class="mr-1 design-button-text"></fa-icon>
    <span class="design-button-text">{{text}}</span>
  </button>
  <button mat-raised-button [matTooltip]="tooltip" [color]="color" disabled *ngIf="disabled">
    <fa-icon *ngIf="icon" [icon]="icon" class="mr-1 design-button-text"></fa-icon>
    <span class="design-button-text">{{text}}</span>
  </button>
  <button mat-raised-button [matTooltip]="tooltip" type="submit" [color]="color" disabled *ngIf="running && !disabled">
    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
    <span class="design-button-text">{{text}}</span>
  </button>
</ng-template>

<ng-template [ngIf]="type === 'stroked-button'">
  <button mat-stroked-button [matTooltip]="tooltip" [color]="color" [type]="buttonType ? buttonType : 'button'" *ngIf="!running && !disabled" (click)="click()">
    <fa-icon *ngIf="icon" [icon]="icon" class="mr-1 design-button-text"></fa-icon>
    <span class="design-button-text">{{text}}</span>
  </button>
  <button mat-stroked-button [matTooltip]="tooltip" [color]="color" disabled *ngIf="disabled">
    <fa-icon *ngIf="icon" [icon]="icon" class="mr-1 design-button-text"></fa-icon>
    <span class="design-button-text">{{text}}</span>
  </button>
  <button mat-stroked-button [matTooltip]="tooltip" type="submit" [color]="color" disabled *ngIf="running && !disabled">
    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
    <span class="design-button-text">{{text}}</span>
  </button>
</ng-template>

<ng-template [ngIf]="type === 'menu-item'">
  <button mat-menu-item [matTooltip]="tooltip" [type]="buttonType ? buttonType : 'button'" *ngIf="!running && !disabled" (click)="click()">
    <fa-icon *ngIf="icon" [icon]="icon" class="mr-1 design-button-text"></fa-icon>
    <span class="design-button-text">{{text}}</span>
  </button>
  <button mat-menu-item [matTooltip]="tooltip" disabled *ngIf="disabled">
    <fa-icon *ngIf="icon" [icon]="icon" class="mr-1 design-button-text"></fa-icon>
    <span class="design-button-text">{{text}}</span>
  </button>
  <button mat-menu-item [matTooltip]="tooltip" type="submit" disabled *ngIf="running && !disabled">
    <span class="spinner-border spinner-border-sm mr-1 design-button-text" role="status" aria-hidden="true"></span>
    <span class="design-button-text">{{text}}</span>
  </button>
</ng-template>

