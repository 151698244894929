import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'design-stepper-next-button',
  templateUrl: './design-stepper-next-button.component.html',
  styleUrls: ['./design-stepper-next-button.component.scss']
})
export class DesignStepperNextButtonComponent implements OnInit {

  @Input()
  text: string;

  @Input()
  direction: 'down' | 'right' = 'down';

  @Input()
  disabled: boolean = false;

  @Output()
  clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
  }

  onClick(): void {
    const matStepContainer = this.elementRef.nativeElement.closest('.mat-step');
    if (matStepContainer) matStepContainer.classList.add('ng-submitted');
    this.clicked.next();
  }

}
