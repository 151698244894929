<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
  <mat-tab-group class="custom-tabs" *ngIf="examples.length > 1">
    <mat-tab *ngFor="let example of examples" [label]="example.name">
      <ng-template matTabContent>
        <iframe [src]="example.src" width="100%" height="100%">
        </iframe>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <ng-container *ngIf="examples.length === 1">
    <iframe [src]="examples[0].src" width="100%" height="100%">
    </iframe>
  </ng-container>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button color="primary"
          mat-dialog-close>{{'example-sanctions-screening-report:Затвори' | translate}}</button>
</div>
