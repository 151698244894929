<mat-form-field [formGroup]="form" class="w-100" [ngClass]="customClasses">
  <mat-label>{{placeholder}}</mat-label>
  <input matInput type="{{type}}"  formControlName="{{name}}">
  <span *ngIf="suffix" matSuffix>{{suffix}}</span>
  <mat-error *ngIf="form.controls[name].hasError('email')">
    {{'design.design-input-text:Внесениот е-маил е' | translate}}
    <strong>{{'design.design-input-text:невалиден' | translate}}</strong>
  </mat-error>
  <mat-error *ngIf="form.controls[name].hasError('minlength')">
    <ng-content select="[minlength]"></ng-content>
  </mat-error>
  <mat-error *ngIf="form.controls[name].hasError('maxlength')">
    <ng-content select="[maxlength]"></ng-content>
  </mat-error>
  <mat-error *ngIf="form.controls[name].hasError('pattern')">
    <ng-content select="[pattern]"></ng-content>
  </mat-error>
  <mat-error *ngIf="form.controls[name].hasError('max')">
    <ng-content select="[max]"></ng-content>
  </mat-error>
  <mat-error *ngIf="form.controls[name].hasError('min')">
    <ng-content select="[min]"></ng-content>
  </mat-error>
</mat-form-field>
