import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "@app/services/language.service";
import {LocalStorageService} from "@app/services/local-storage.service";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {environment} from "@env/environment";

@Component({
  selector: 'app-language-root',
  templateUrl: './language-root.component.html',
  styleUrls: ['./language-root.component.scss']
})
export class LanguageRootComponent implements OnInit {

  url: string;

  alternateLinks: { lang: string, uri: string }[];

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private translate: TranslateService,
              private languageService: LanguageService,
              private localStorageService: LocalStorageService,
              private titleService: Title,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private metaService: Meta,
              @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.url = this.router.url;
    const pathLanguage = this.router.url.substr(1, 2);
    if (pathLanguage) {
      this.languageService.setLanguageFromUrl(pathLanguage);
    }
    this.setDocumentTitle(this.languageService.getCurrentLanguage());
    this.alternateLinks = environment.availableLanguages
      .map(it => it.code)
      .filter(it => it !== this.languageService.getCurrentLanguage())
      .map(it => ({
        lang: it,
        uri: this.router.url.replace('/' + this.languageService.getCurrentLanguage() + '/', '/' + it + '/')
      }))
  }

  private setDocumentTitle(lang: string) {
    this.document.documentElement.lang = lang;
  }
}
