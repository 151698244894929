<app-header></app-header>
<div class="home">
  <div class="px-5">
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <div class="my-5">
          <div class="card h-100">
            <div class="card-body text-center">
              <h2 class="main-title card-title mb-3 mt-4"><b>{{'home:AML сервис'| translate}}</b></h2>
              <p
                class="card-text mt-4">{{'home:Metricity е online платформа за менаџирање'| translate}}</p>
              <p></p>
              <p></p>
            </div>
            <div class="card-footer text-center">
              <button mat-raised-button color="primary" routerLink="../app/sign-up" class="mat-elevation-z4 px-4 py-2"><span
                style="font-size: 1.3em">{{'home:3€ / клиент'| translate}}</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="reports-container row justify-content-center">
      <!-- <div class="col-md-1 mx-auto" >
            <div class="my-5 h-100">&nbsp;</div>
        </div> -->
      <div class="col-lg-4 d-flex align-items-stretch">
        <div class="my-5 w-100">
          <div class="card h-100">
            <div class="card-body text-center">
              <div>
                <a routerLink="../reports/sanctions-screening"
                   [queryParams]="{show_example: 1}">{{'home:Види пример' | translate}}</a>
              </div>
              <h2 class="card-title mb-3 mt-4"><b>{{'home:Основен скрининг извештај' | translate}}</b></h2>
              <p
                class="card-text mt-4">{{'home:Пребарувања во Консолидирани листи на САД, ЕУ и ОН; Носител на јавна функција во МК (ПЕП статус)'  | translate}}</p>
            </div>
            <div class="card-footer text-center">
              <button mat-raised-button color="primary" routerLink="../reports/sanctions-screening"
                      class="btn btn-primary mat-elevation-z4 px-4 py-2"><span
                style="font-size: 1.3em">{{'home:Порачај за 8€' | translate}}</span></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch">
        <div class="my-5">
          <div class="card h-100">
<!--            <img class="promo-code-icon" src="/assets/landing-page/promo-code-icon.png" alt="Promo Code"-->
<!--                 matTooltip="{{'home:Плати со промо код' | translate}}">-->
            <div class="card-body text-center">
              <div>
                <a routerLink="../reports/world-report"
                   [queryParams]="{show_example: 1}">{{'home:Види пример' | translate}}</a>
              </div>
              <h2 class="card-title mb-3 mt-4"><b>{{'home:Acuris скрининг извештај' | translate}}</b></h2>
              <p
                class="card-text mt-4">{{'home:Санкциони листи во САД, Азија-Пацифик, Европа, Среден Исток и Африка; ПЕП - Светско ниво и МК; Неликвидност; Несакани информации во медиуми; Пребарување во ФАТФ' | translate}}</p>
            </div>
            <div class="card-footer text-center">
              <button mat-raised-button color="primary" routerLink="../reports/world-report" class="btn btn-primary mat-elevation-z4 px-4 py-2"><span
                style="font-size: 1.3em">{{'home:Порачај за 20€' | translate}}</span></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch">
        <div class="my-5">
          <div class="card h-100">
            <div class="card-body text-center">
              <div>
                <a routerLink="../reports/risk-calculation"
                   [queryParams]="{show_example: 1}">{{'home:Види пример' | translate}}</a>
              </div>
              <h2 class="card-title mb-3 mt-4">
                <b>{{'home:Acuris скрининг извештај со проценка на ризик' | translate}}</b></h2>
              <p
                class="card-text mt-4">{{'home:Санкциони листи во САД, Азија-Пацифик, Европа, Среден Исток и Африка; ПЕП - Светско ниво и МК; Неликвидност; Несакани информации во медиуми; Пребарување во ФАТФ; Проценка на ризик од: клиент, географско подрачје, продукт, канал на дистрибуција' | translate}}</p>
            </div>
            <div class="card-footer text-center">
              <button mat-raised-button color="primary" routerLink="../reports/risk-calculation" class="btn btn-primary mat-elevation-z4 px-4 py-2"><span
                style="font-size: 1.3em">{{'home:Порачај за 65€' | translate}}</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<shared-swiper [images]="images"></shared-swiper>
<div class="container mb-5">
  <div class="row">
    <div class="col-md-4">
      <div class="card h-100 border-0">
        <div class="card-body text-center">
          <div class="card-title my-5"><i class="material-icons icon-display">
            policy
          </i></div>
          <p class="card-text "><b>{{'home:СКРИНИНГ НА САНКЦИОНИ ЛИСТИ'| translate}}</b></p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100 border-0">
        <div class="card-body text-center">
          <div class="card-title my-5"><i class="material-icons icon-display">
            how_to_reg
          </i></div>
          <p class="card-text"><b>{{'home:ВЕРИФИКАЦИЈА НА ИДЕНТИТЕТОТ НА КЛИЕНТОТ'| translate}}</b></p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100 border-0">
        <div class="card-body text-center">
          <div class="card-title my-5"><i class="material-icons icon-display">
            assignment
          </i></div>
          <p class="card-text"><b>{{'home:АМЛ ИЗВЕШТАИ'| translate}}</b></p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="card h-100 border-0">
        <div class="card-body text-center">
          <div class="card-title my-5"><i class="material-icons icon-display">
            network_check
          </i></div>
          <p class="card-text"><b>{{'home:АМЛ ПРОЦЕНКА НА РИЗИК'| translate}}</b></p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100 border-0">
        <div class="card-body text-center">
          <div class="card-title my-5"><i class="material-icons icon-display">
            portrait
          </i></div>
          <p class="card-text"><b>{{'home:АНАЛИЗА НА КЛИЕНТСКО ДОСИЕ'| translate}}</b></p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100 border-0">
        <div class="card-body text-center">
          <div class="card-title my-5"><i class="material-icons icon-display">
            new_releases
          </i></div>
          <p class="card-text"><b>{{'home:ИНДИКАТОРИ И ФАКТОРИ ЗА РИЗИК'| translate}}</b></p>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="background: #007cab">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 col-md-12 tst-img">
        <img style="width: 80px;" class="my-3" src="/assets/landing-page/logo-kb-publikum.png" alt="">
      </div>
      <div class="col-md-10 mx-auto text-testimonial">
        <p
          class="mt-4 text-white">{{'home:Metricity е корисна софтверска алатка која ја олеснува проценката на ризикот од перење пари за секој поединечен клиент - физичко лице преку проверки на три санкциони листи и преку проверка дали клиентот е носител на јавна функција во нашата држава.'| translate}}</p>
        <p class="text-white float-right">
          {{'home:КБ ПУБЛИКУМ ИНВЕСТ АД Скопје - Друштво за управување со инвестициски фондови'| translate}}
        </p>
      </div>
    </div>
  </div>
</div>
<div style="background: #00AEEFFF;">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 col-md-12 tst-img">
        <img style="width: 80px;" class="my-3" src="/assets/landing-page/tanja.jpg" alt="">
      </div>
      <div class="col-md-10 mx-auto text-testimonial">
        <p
          class="mt-4 text-white">{{'home:Многу корисна платформа. Значително ја олеснува организацијата на процесот на проверка на клиенти и проценка на ризик, а самата проверка ја автоматизира и елиминира човечки грешки.'| translate}}</p>
        <p class="text-white float-right">
          {{'home:Татјана Вељковиќ, СЕАФ Македонија'| translate}}
        </p>
      </div>
    </div>
  </div>
</div>
<app-footer class="py-4"></app-footer>
