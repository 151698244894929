import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'design-stepper-previous-button',
  templateUrl: './design-stepper-previous-button.component.html',
  styleUrls: ['./design-stepper-previous-button.component.scss']
})
export class DesignStepperPreviousButtonComponent implements OnInit {

  @Input()
  text: string;

  @Input()
  direction: 'up' | 'left' = 'up';


  @Output()
  clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  onClick(): void {
    this.clicked.next();
  }

}
