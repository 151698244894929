import {Component, OnInit} from '@angular/core';
import {LanguageService} from '@app/services/language.service';
import {environment} from '@env/environment';

@Component({
  selector: 'shared-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  currentLanguage: string;
  availableLanguages = environment.availableLanguages;

  constructor(private languageService: LanguageService) {
  }

  ngOnInit() {
    this.currentLanguage = this.languageService.getCurrentLanguage();
  }

  setLanguage(language: string) {
    this.languageService.changeLanguage(language);
  }

}
