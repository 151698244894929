import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import {Manager} from 'metricity-shared/src/interfaces/models/manager';
import {Organisation} from 'metricity-shared/src/interfaces/models/organisation';
import {User} from 'metricity-shared/src/interfaces/models/user';
import {OrganisationLevelOneType} from 'metricity-shared/src/interfaces/models/organisation-level-one-type';
import {MatDialog} from '@angular/material/dialog';
import {AddLanguagePrefixPipe} from "@app/shared/pipes/add-language-prefix.pipe";
import {LocalStorageService} from "@app/services/local-storage.service";

class LoginResponse {
  accessToken: string;
  refreshToken: string;
  role: 'MANAGER' | 'ADMIN';
  user: User;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  protected domain = environment.domain;


  constructor(private http: HttpClient, private router: Router, private dialog: MatDialog,
              private addLanguagePrefixPipe: AddLanguagePrefixPipe,
              private localStorageService: LocalStorageService) {
  }

  getClientTypes(): Observable<OrganisationLevelOneType[]> {
    return this.http.get<OrganisationLevelOneType[]>(`${this.domain}/auth/client-types`);
  }

  signUpClientOrganisation(manager: Manager, organisation: Organisation): Observable<Manager> {
    return this.http.post<Manager>(this.domain + '/auth/sign-up-client-organisation', {manager, organisation});
  }

  login(email: string, password: string, tfaCode: string): Observable<HttpResponse<LoginResponse>> {
    return this.http.post<LoginResponse>(this.domain + '/auth/login', {email, password, tfaCode}, {observe: 'response'}).pipe(
      tap((res: any) => {
        if (res.status === 200) {
          this.localStorageService.setAccessToken(res.body.accessToken);
          this.localStorageService.setRefreshToken(res.body.refreshToken);
        }
      })
    );
  }

  refreshAccessToken() {
    const refreshToken = this.localStorageService.getRefreshToken();
    return this.http.post<LoginResponse>(this.domain + '/auth/refreshToken', {refreshToken}).pipe(
      tap((body) => {
        this.localStorageService.setAccessToken(body.accessToken);
        this.localStorageService.setRefreshToken(body.refreshToken);
      })
    );
  }

  public checkTokenValidity(token: string): Observable<object> {
    return this.http.post(this.domain + '/auth/check-token-validity', {token: token});
  }

  forgotPassword(email: string): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(this.domain + '/auth/forgot-password', {email: email});
  }

  setPassword(token: string, password: string, confirmPassword: string, acceptedTermsAndPrivacyPolicies: boolean): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(this.domain + '/auth/set-password', {
      token,
      password,
      confirmPassword,
      acceptedTermsAndPrivacyPolicies
    });
  }

  logout() {
    this.dialog.closeAll();
    this.localStorageService.removeAccessToken();
    this.localStorageService.removeRefreshToken();
    const newPath = this.addLanguagePrefixPipe.transform('/app/login');
    if (window.location.pathname !== newPath) {
      window.location.href = newPath; // new window location, to clear any states
    }
  }

  getRoleFromToken(token): Observable<{ role: string }> {
    return this.http.get<{ role: string }>(this.domain + '/auth/get-role', {headers: {'Authorization': `Bearer ${token}`}});
  }

  tfaSetup(): Observable<{ message: string, dataUrl: string, tempSecret: string }> {
    return this.http.post<{ message: string, dataUrl: string, tempSecret: string }>(this.domain + '/tfa/setup', {});
  }

  tfaVerify(tfaCode: string): Observable<{ message: string, dataUrl: string, tempSecret: string, recoveryCode?: string }> {
    return this.http.post<{ message: string, dataUrl: string, tempSecret: string }>(this.domain + '/tfa/verify', {tfaCode: tfaCode});
  }

  disableTfa(): Observable<{ message: string, dataUrl: string, tempSecret: string }> {
    return this.http.post<{ message: string, dataUrl: string, tempSecret: string }>(this.domain + '/tfa/disable', {});
  }

}

