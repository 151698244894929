import {Injectable} from '@angular/core';
import {catchError, map, merge, publishReplay, refCount, shareReplay, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable()
export class RxUtilsService {

  constructor() {

  }

  private createSafeRequest<T>(request: Observable<T>, errorMessage: string, valueOnError?: T) {
    return request.pipe(
      catchError(error => {
        return of(valueOnError);
      }));
  }

  /**
   * The observable will call the requestFunction only once, even if it has several subscriptions.
   * It will keep the last value even when all subscriptions are unsubscribed
   * (which allows caching for any new subscriptions)
   */
  public createGetStateValue<T>(initiator: Observable<any>, getRequest: Observable<T>,
                                errorMessage: string, valueOnError?: T): Observable<T> {
    return initiator.pipe(
      switchMap(() => this.createSafeRequest(getRequest, errorMessage, valueOnError)),
      shareReplay(1)
    );
  }

  /**
   * The observable will will the requestFunction only once, even if it has several subscriptions.
   * It will keep the last value even when all subscriptions are unsubscribed
   * (which allows caching for any new subscriptions)
   */
  public createGetValue<T>(initiator: Observable<any>, getRequestFunction: (value) => Observable<T>,
                           errorMessage: string, valueOnError?: T): Observable<T> {
    return initiator.pipe(
      switchMap(initiatorValue => this.createSafeRequest(getRequestFunction(initiatorValue), errorMessage, valueOnError)),
      shareReplay(1)
    );
  }

  /**
   * The observable will will the requestFunction only once, even if it has several subscriptions.
   * It will unsubscribe once all subscriptions are unsubscribed.
   */
  public createSharedValueNotCached<T>(initiator: Observable<any>, getRequestFunction: (value) => Observable<T>,
                                       errorMessage: string, valueOnError?: T): Observable<T> {
    return initiator.pipe(
      switchMap(initiatorValue => this.createSafeRequest(getRequestFunction(initiatorValue), errorMessage, valueOnError)),
      publishReplay(1),
      refCount()
    );
  }

  public createReadingInfo<T>(initiator: Observable<any>, valueToBeRead: Observable<T>): Observable<boolean> {
    return initiator.pipe(
      map(it => true),
      merge(valueToBeRead.pipe(map(() => false))),
      shareReplay(1),
    );
  }

  /**
   * The observable will will the requestFunction only once, even if it has several subscriptions.
   * It will unsubscribe once all subscriptions are unsubscribed.
   */
  public createReadingInfoSharedNotCached<T>(initiator: Observable<any>, valueToBeRead: Observable<T>): Observable<boolean> {
    return initiator.pipe(
      map(it => true),
      merge(valueToBeRead.pipe(map(() => false))),
      publishReplay(1),
      refCount()
    );
  }

}
