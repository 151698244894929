import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {LocalStorageService} from "@app/services/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class HttpLanguageHeaderInterceptorService implements HttpInterceptor {

  constructor(private localStorageService: LocalStorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        'Accept-Language': this.localStorageService.getLanguageCode() || environment.defaultLanguage
      }
    });
    return next.handle(req);
  }

}
