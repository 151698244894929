<div *ngIf="!displayLoader && !showSpinner">
  <div class="vh-140">
    <app-header [showLoginActionButtons]="false"></app-header>
    <div class="bg-img"></div>
    <div class="my-5">
      <div *ngIf="displaySideImage else signup" class="col-12 p-0 m-auto login">
        <div class="login__image">
          <div class="login__image-bg" [ngStyle]="{background: 'white url(' + imageUrl + ') no-repeat center'}"></div>
          <div class="login__image-content">
            <div class="login__image-content-logo">

            </div>
            <div class="login__image-content-text">
              <h1>{{'login:Добредојде'| translate}}</h1>
              <p>{{'login:Најави се или регистрирај се'| translate}}</p>
            </div>
            <a href="/" class="login__image-content-link">www.metricity.mk</a>
          </div>
        </div>
        <ng-content select="[slot=sideImage]"></ng-content>
      </div>


      <ng-template #signup>
        <ng-content select="[slot=noSideImage]"></ng-content>
      </ng-template>
    </div>
  </div>
</div>

<div *ngIf="displayLoader || showSpinner" class="spinner-loader">
  {{'login:Се вчитува...'| translate}}
  <br>
  <mat-spinner [diameter]="80"></mat-spinner>
</div>
