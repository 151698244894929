<div class="row">
  <div class="col-md-3 mt-5">
    <h5 class="text-center"><b>{{'footer:Поддржано од:'| translate}}</b></h5>
    <div class="text-center mt-3"><img style="width:210px" src="/assets/landing-page/fitr.png" alt=""></div>
  </div>
  <div class="col-md-3 mt-5">
    <h5 class="text-center"><b>{{'footer:Продукт'| translate}}</b></h5>
    <ul class="text-center mt-3" style="padding: 0;
    list-style-type: none;">
      <li><a routerLink="../how-it-works">{{'footer:Како работи'| translate}}</a></li>
      <li><a routerLink="../price">{{'footer:Цени'| translate}}</a></li>
      <li>&nbsp;</li>
    </ul>
  </div>
  <div class="col-md-3 mt-5">
    <h5 class="text-center"><b>{{'footer:За компанијата'| translate}}</b></h5>
    <ul class="text-center mt-3" style="padding: 0;
    list-style-type: none;">
      <li><a routerLink="../privacy-policy">{{'footer:Политика на приватност'| translate}}</a></li>
      <li><a routerLink="../terms">{{'footer:Правила на користење'| translate}}</a></li>
      <li>&nbsp;</li>
    </ul>
  </div>
  <div class="col-md-3 mt-5">
    <h5 class="text-center"><b>{{'footer:Повеќе'| translate}}</b></h5>
    <ul class="text-center mt-3" style="padding: 0;
    list-style-type: none;">
      <li><a routerLink="../contact">{{'footer:Контактирај нѐ'| translate}}</a></li>
      <li>&nbsp;</li>
    </ul>
  </div>
</div>

