import {Component, OnInit, ViewChild} from '@angular/core';
import {ExampleReportComponent} from '@app/reports/components/example-sanctions-screening-report/example-report.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  images = [
    '/assets/landing-page/slide1_compressed.png',
    '/assets/landing-page/slide2_compressed.png',
    '/assets/landing-page/slide3_compressed.png',
    '/assets/landing-page/slide4_compressed.png',
    '/assets/landing-page/slide5_compressed.png',
    '/assets/landing-page/slide6_compressed.png',
    '/assets/landing-page/slide7_compressed.png',
    '/assets/landing-page/slide8_compressed.png',
  ];
  imagesLoaded = 0;
  allImagesLoaded = false;

  constructor(private dialog: MatDialog,
              private translate: TranslateService) {
  }

  ngOnInit() {
  }

  openExampleReport(reportType: 'SANCTIONS_SCREENING' | 'WORLD_REPORT' | 'RISK_CALCULATION') {
    this.dialog.open(ExampleReportComponent, {
      width: '60%',
      height: '90%',
      autoFocus: false,
      disableClose: false,
      data: this.getDataForExampleReport(reportType)
    });
  }

  private getDataForExampleReport(reportType: 'SANCTIONS_SCREENING' | 'WORLD_REPORT' | 'RISK_CALCULATION'): { title: string, examples: Array<{ name: string, file: string }> } {
    switch (reportType) {
      case 'SANCTIONS_SCREENING': {
        return {
          title: this.translate.instant('home:Основен скрининг извештај'),
          examples: [{name: 'Sanctions Screening', file: 'sanctions-example.pdf'}]
        };
      }
      case 'WORLD_REPORT': {
        return {
          title: this.translate.instant('home:Acuris скрининг извештај'),
          examples: [{name: 'World Report', file: 'world-report-example.pdf'}]

        };
      }
      case 'RISK_CALCULATION': {
        return {
          title: this.translate.instant('home:Acuris скрининг извештај со проценка на ризик'),
          examples: [
            {
              name: this.translate.instant('home:Проценка на ризик'),
              file: 'risk-calculation-example.pdf'
            },
            {name: 'World Report', file: 'risk-calculation-world-report-example.pdf'}
          ]
        };
      }
    }
  }

  onImageLoad(){
    this.imagesLoaded++;

    if(this.imagesLoaded == this.images.length){
      this.allImagesLoaded = true;
    }
  }
}
