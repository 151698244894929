import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AuthenticationService} from '@app/services/authentication.service';
import {finalize} from 'rxjs/operators';
import {SignupPrivacyPolicyComponent} from '@app/components/signup-privacy-policy/signup-privacy-policy.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SignupTermsPolicyComponent} from '@app/components/signup-terms-policy/signup-terms-policy.component';
import {NotificationComponent} from '@app/shared/components/notification/notification.component';
import {TranslateService} from '@ngx-translate/core';
import {isConfirmPasswordValid} from '@app/utils/validators/isConfirmPasswordValid.validator';
import {AddLanguagePrefixPipe} from "@app/shared/pipes/add-language-prefix.pipe";
import {LocalStorageService} from "@app/services/local-storage.service";

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  showSpinner = true;
  token = null;
  requiredAcceptedTermsAndPrivacyPolicies = false;

  form: FormGroup;

  changingPassword$ = new BehaviorSubject(false);
  success = false;
  error = false;

  subscriptionOnParams: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private authenticationService: AuthenticationService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private addLanguagePrefixPipe: AddLanguagePrefixPipe,
              private localStorageService: LocalStorageService) {
    this.form = new FormGroup({
      password: new FormControl(null,
        [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]),
      confirmPassword: new FormControl(null, [Validators.required]),
      acceptedTermsAndPrivacyPolicies: new FormControl(false)
    }, [isConfirmPasswordValid('password', 'confirmPassword')]);

  }

  ngOnInit() {
    const token = this.localStorageService.getAccessToken();
    let notLogged = true;
    if (token) {
      notLogged = false;
      this.authenticationService.getRoleFromToken(token).subscribe((result) => {
        if (result.role === 'ADMIN') {
          this.router.navigateByUrl(this.addLanguagePrefixPipe.transform('/app/admin'));
        }
        if (result.role === 'MANAGER') {
          this.router.navigateByUrl(this.addLanguagePrefixPipe.transform('/app/client'));
        }
      }, (err) => notLogged = true);
    }
    if (notLogged) {
      this.subscriptionOnParams = this.route.queryParams.subscribe(queryParams => {
        if (queryParams.token != null) {
          this.token = queryParams.token;
          this.authenticationService.checkTokenValidity(this.token).subscribe(response => {
            this.showSpinner = false;
            // Do nothing
          }, error => {
            this.showSpinner = false;
            this.translate.get('set-password:Токенот е невалиден').subscribe((translation) => {
              this.snackBar.openFromComponent(NotificationComponent, {
                data: {
                  message: translation
                },
                panelClass: 'danger-snackbar',
                duration: 5000
              });
            });
            return this.router.navigate([this.addLanguagePrefixPipe.transform('/app/login')]);
          });
          if (queryParams.terms === '1') {
            this.requiredAcceptedTermsAndPrivacyPolicies = true;
          }
        } else {
          this.router.navigate([this.addLanguagePrefixPipe.transform('/app/forgot-password')]);
        }
      });
    }
  }

  setPassword() {
    this.changingPassword$.next(true);
    this.error = false;
    this.authenticationService.setPassword(
      this.token,
      this.form.value['password'],
      this.form.value['confirmPassword'],
      this.form.value['acceptedTermsAndPrivacyPolicies']
    )
      .pipe(
        finalize(() => this.changingPassword$.next(false))
      )
      .subscribe((result) => {
        this.success = true;
      }, (error) => {
        this.error = true;
      });
  }

  showTermsDialog() {
    this.dialog.open(SignupTermsPolicyComponent, {
      autoFocus: false,
      disableClose: true
    });
  }

  showPrivacyDialog() {
    this.dialog.open(SignupPrivacyPolicyComponent, {
      autoFocus: false,
      disableClose: true
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptionOnParams) {
      this.subscriptionOnParams.unsubscribe();
    }
  }

  goToLogin() {
    window.open(this.addLanguagePrefixPipe.transform('/app/login'), '_blank');
    window.close();
  }

}
