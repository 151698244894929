<div class="main-container">
  <app-header></app-header>
  <div class="container contact-form">
    <div class="row">
      <div class="col contact-image">
        <img src="assets/logo3.png"/>
      </div>
    </div>
    <div class="pb-5">
      <h3>{{'contact:Контактирај нѐ'| translate}}</h3>
      <div class=" text-center">
        <h5><a class="" href="tel:+389-76-445-302">+389 76 445 302</a></h5>
        <h5><a href="mailto: support@metricity.mk">support&#64;metricity.mk</a></h5>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
