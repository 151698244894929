import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {CountriesService} from '../services/countries.service';
import {RiskCountry} from 'metricity-shared/src/interfaces/models/risk-country';

@Injectable()
export class CountriesStateService {

  private countries: Observable<RiskCountry[]>;

  constructor(countriesService: CountriesService) {
    this.countries = countriesService.getCountries().pipe(
      shareReplay(1)
    );
  }

  getCountries() {
    return this.countries;
  }
}
