<app-header></app-header>
<div class="aboutus-section">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="aboutus">
          <div class="about-tr">
            <h5 class="aboutus-title">Metricity <br> во медиумите</h5>
            <button class="btn btn-link " (click)="setPost(1)">
                biznisinfo.mk
            </button>
            <button class="btn btn-link " (click)="setPost(2)">
                inovativnost.mk
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-9 col-sm-9 col-xs-12" [ngSwitch]="choice">
        <div class="aboutus" >
          <div class="about-tr" *ngSwitchCase="1">
            <h2 class="aboutus-title">Metricity – софтверско решение за спречување на перење на пари и финансирање на тероризам</h2>
            <p class="aboutus-text">
                Напишано од Александра Петровска <a class="ml-3 " href="https://biznisinfo.mk/metricity-softversko-reshenie-za-sprechuvanje-na-perenje-na-pari-i-finansiranje-na-terorizam/">biznisinfo.mk</a>
            </p>
            <div class="aboutus-text" >
              <p>
                Перењето на пари и финансирање на тероризам е проблематика којашто зазема замав на глобално ниво и
                станува една од најжешките теми за кои што секојдневно се известува во дневните медиуми во насока како
                институциите обврзници на Законот за спречување на перење на пари и финансирање на тероризам не презеле
                очекувани активности и дејности и индиректно или пак директно го поддржале перењето на пари преку
                нивните платежни системи. На тој начин платиле финансиски казни, ја изгубиле дозволата за вршење на
                дејност или пак нивната репутација е нарушена.
              </p>

                <p>
                  Законските одредби и во Република Северна Македонија се, исто така, дефинирани и обврзувачки за многу
                  ентитети: финансиски институции, правни и физички лица кои вршат посредување во промет со недвижности,
                  ревизорски и сметководствени услуги, давање совети од областа на даноците, давање на услуги како
                  инвестициони советници, нотари, адвокати, приредувачи на игри на среќа, Централен депозитар на хартии од
                  вредност, дејност на примање во залог на подвижни предмети и недвижности, итн.

                </p>  
                <div class="text-center mb-3"><img class="img-fluid mx-auto w-75" src="https://biznisinfo.mk/wp-content/uploads/2019/05/binary-2372130_960_720-696x464.jpg" alt=""></div>
                <p>
                  ИТ компанијата Доларе Технолоџи е современа компанија која увиде дека домашните субјекти, како и
                  глобалните ентитети имаат проблем да бидат усогласени со законските одредби, да го верификуваат
                  идентитетот на клиентот и крајниот корисник, како и да го проценат ризикот на клиентот од перење пари и
                  финансирање тероризам. На сите им недостасува комплетно современо cloud base решение за спречување на
                  перење на пари и финансирање на тероризам што ќе им ги реши проблемите, ќе го олесни донесувањето
                  одлуки, ќе им заштеди пари и истовремено ќе им го зголеми профитот задржувајќи ги ризичните клиентите.
                  Софтверското решение ќе биде прилагодливо на секој вид на институција без разлика на големината дали е
                  мал, среден или голем субјект, односно корпорација.
                </p>

                <p>
                  Компанијата има комплетно знаење за изработка на ваква апликација и во тимот учествуваат софтверски
                  инженери со искуство во развивање на кориснички апликации, професионалци од областа на спречување на
                  перење на пари и финансирање на тероризам кои работеле долги години во меѓународни институции,
                  лиценцирани меѓународни ревизори и експерти со управување со ризикот.
                </p>

                <p>
                  Токму различното богато искуство ја прави оваа ИТ апликација Metricity совршена. Она што го прават ИТ
                  компаниите заедно со нивните клиенти, компанијата го нуди како комплетно решение кое што ги содржи
                  законските одредби од Законот за спречување на перење на пари и финансирање на тероризам.
                </p>

                <p>
                  Многу е поедноставно да се направи ИТ апликација од личности кои веќе го поминале тешкиот пат и кои што
                  искусиле многу недостатоци со било која ИТ апликација. Клиенти, исто така, имаат можност во соработка со
                  тимот да имплементираат и нивни барања во апликацијата и да добијат многу совети за поефикасни решенија.
                  Искуството е основа и на соработката со партнер од Соединетите Американски Држави, каде што покрај
                  останатите софтверски решенија се работеше и на софтверски решенија за спречување на перење на пари и
                  финансирање на тероризам наменет за финансиски институции Јужна Америка и во Европа. Капацитетот и
                  иновативност го препозна две институции, Фондот за иновации и технолошки развој и УКИМ акцелераторот,
                  коишто со својата финансиска поддршка несомнено помагаат ова решение да ја добие својата најубава форма.
                </p>
              
            </div>
          </div>
          <div class="about-tr" *ngSwitchCase="2">
            <h2 class="aboutus-title">Ова е македонскиот експертски тим кој направи платформа за заштита на компаниите од перење пари</h2>
            <p class="aboutus-text">
                <a class="" href="https://inovativnost.mk/2019/08/10/%d0%be%d0%b2%d0%b0-%d0%b5-%d0%bc%d0%b0%d0%ba%d0%b5%d0%b4%d0%be%d0%bd%d1%81%d0%ba%d0%b8%d0%be%d1%82-%d0%b5%d0%ba%d1%81%d0%bf%d0%b5%d1%80%d1%82%d1%81%d0%ba%d0%b8-%d1%82%d0%b8%d0%bc-%d0%ba%d0%be%d1%98/">inovativnost.mk</a>
            </p>
            <div class="aboutus-text" >
              <p>
                  Глобалниот пазар, во современите услови на функционирање на компаниите, им е достапен на сите, вклучувајќи ги сите негови предности и опасности. Ризикот од соработка со сомнителни клиенти и потенцијалните опасности од перење пари и финансирање тероризам се закана за секоја компанија и институција. Едно од решенијата за надминување на овие ризици го има компанијата Citechsuite, која работи од 2017 година.
              </p>
              <div class="text-center">
                  <img class="img-fluid w-50 mb-3" src="/assets/landing-page/1.jpg" alt="">
              </div>
                  <p>За платформата Metricity и за нејзините можности разговараме со првиот човек на компанијата Александар Велков. Тој објаснува дека тимот, составен од софтверските инженери Никола Кусибојовски и Симеон Здравески,  Билјана Секуловска и Магдалена Малезанска кои доаѓаат од банкарскиот сектор,  техничкиот лидер Драган Захариев и Бојан Поп Трајков, сака своето искуство да го преточи во софтверски продукт. Визија им е да постават стандард за справување со финасиска измама.  Metricity – Know Your Client, посочува Велков, е првиот продукт, а во план се уште три, Transaction Monitoring, Reporting Module и Regulatory Compliance Module!</p>
                  
                  <div class="text-center">
                      <img class="img-fluid" src="/assets/landing-page/Aleksandar.jpg" alt="">
                  </div>
                  <p class="text-center">Александар Велков</p>
                  
                  <p><b>Г-дин Велков, објаснете ни подетално какво решение е платформата Metricity?</b></p>
                  
                  <p>Metricity е софтверско решение кое врши анализа на ризик од перење пари и финансирање тероризам, врши верификација на клиентот и при тоа овозмoжува усогласеност со Законот за спречување на перење пари и финансирање на тероризам (ЗСППФТ). Metricity е направена за субјектите кои се обврзани да ги преземаат мерките и дејствијата за спречување на перење пари и финансирање на тероризам согласно ЗСППФТ, за компаниите кои сакаат да внесат повисоки стандарди во своето работење и да се осигураат дека лицата со кои соработуваат нема да го изложат нивниот бизнис на дополнителни ризици.</p>
                  
                  <p><b>Како клиентите можат да ја користат платформата?</b></p>
                  
                  <p>Пристапот до Metricity е преку брза и едноставна регистрација на онлајн платформата, а клиентот откако ќе се регистрира ја одбира опцијата фактори на ризик, селектира периодични проверки на меѓународни црни листи и ембарго страни и сето тоа во рок од една до две минути му овозможува да почне да го користи Metricity. Сите кои го користат Metricity можат да ги внесат своите клиенти рачно, да ги импортираат преку фајл или да ги повикаат нашите адаптери за размена на податоци т.н „API интерфејси“. Системот генерира предупредувања доколку недостасуваат податоци за клиентите, доколку има потенцијално совпаѓање со лице на меѓународни или црни листи, доколку некој клиент е проценет како ризичен од аспект на перење пари, доколку има одредени сомневања во врска со клиентите. Системот нуди механизми за намалување и за менаџирање на ризикот од перење пари и финансирање на тероризам, со цел да ја намали ранливоста на бизнисот од овие ризици. Metricity во овој момент е достапен на македонскиот пазар, а во 2020 година софтверот ќе биде достапен и глобално.</p>
                  
                  <div class="text-center">
                      <img class="img-fluid mb-3" src="/assets/landing-page/3.jpg" alt="">
                  </div>
                  
                  <p><b>Која е вашата иновација што ги привлекува клиентите на Metricity?</b></p>
                  
                  <p>Metricity претставува т.н „SaaS – Software as a Service”. Што значи тоа? На малите и средните компании и субјекти им нудиме услуга AML- Одговорно Лице, преточено во Metricity. Наместо да вложуваат во регрутирање нови вработени, како и тренинг на тие вработени, овие компании ќе го користат Metricity за таа потреба и ќе го намалат трошокот. Во цената за користење на Metricity е вклучена и обука. На големите фирми и субјекти кои законски се обврзани да имаат оддели за спречување на перење пари им нудиме услуга АМЛ- Оддел, преточен во Metricity. Одделот има увид како се пресметува проценката на ризик од перење пари за клиентите и соработниците и во исто време вработените во Одделот ќе можат сами да креираат и подесуваат свои индикатори за ризик без да имаат познавање од програмирање.</p>
                  
                  <p><b>Кои се предностите за компаниите од користење на Metricity?</b></p>
                  
                  <p>Компаниите ќе го користат Metricity преку месечна претплата, што значи дека ќе имаат месечен трошок, а нема да имаат потреба од стартна инвестиција, што е случај кога набавуваат друг софтвер. Дополнително, овој принцип значително го намалува периодот до започнување со користењето на софтверот, а на клиентите им се нуди и техничка поддршка и воведна обука. Metricity е достапен од било кој уред и од било кое место во светот сè што треба е интернет конекција. Сите клиенти на компаниите и субјектите имаат автоматски генерирано ризичен профил (низок, среден и висок) по првичната регистрација и во секое време може да се прегледа или да се испечати како извештај. И на клиентите им овозможуваме директен пристап до Metricity преку „Client App“, што ќе им го олесни процесот на компаниите при целосна верификација на нивните клиенти. За лица кај кои ќе се утврди висок ризик, се нудат механизми за намалување на ризикот, кои се дел од засилената анализа преку Metricity.</p>
                  
                  <p><b>Кои се ризиците доколку компаниите не се усогласени со позитивната регулатива против перење пари?</b></p>
                  
                  <p>Регулаторот предвидува парична казна за овие компании, како и за самите одговорни лица во компаниите. Репутацискиот ризик е присутен и тој може негативно да влијае на бизнисот во целина. Секојдневно сме сведоци на случаи кога негативната репутација уништува цели компании. Доколку пак некои компании и субјекти едноставно сакаат да работат само со веќе познати клиенти, а новите и непознати клиенти кои се ризични ги одбиваат, тоа може значително да им влијае на профитот. Ние нудиме решение и за тие клиенти.</p>
                  
                  <div class="text-center">
                      <img class="img-fluid mb-3" src="/assets/landing-page/4.jpg" alt="">
                  </div>
                  
                  <p><b>Соработувате и со ФИТР и со УКИМ акцелераторот. Кажете ни нешто повеќе за таа поддршка.</b></p>
                  
                  <p>Аплициравме на повикот „Стартапувај“ од ФИТР, во рамките на Инструментот за поддршка – Кофинансирани грантови за новоосновани трговски друштва „старт-ап“ и „спин-оф“. Вкупната вредност на проектот 35,219 евра, со тоа што 15 отсто од тие средства претставува сопствено ко-финансирање. Преку УКИМ акцелераторот влеговме во акцелераторска програма која траеше осум недели. Целата на таа програма беше да го приспособи продуктот и идејата за продуктот за претставување пред потенцијални инвеститори, и на крајот на самата програма имаше демо ден, каде сите компании кои бевме дел од програмата ги претставивме своите продукти пред потенцијални инвеститори.</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>