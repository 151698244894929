import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {UserStateService} from '@app/states/user-state.service';
import {AddLanguagePrefixPipe} from "@app/shared/pipes/add-language-prefix.pipe";

@Injectable({
  providedIn: 'root'
})
export class ClientGuard implements CanActivate {
  constructor(private userStateService: UserStateService,
              private router: Router,
              private addLanguagePrefixPipe: AddLanguagePrefixPipe) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userStateService.getUser().pipe(
      switchMap(async (user) => {
        if (user && user.role.name === 'MANAGER') {
          return true;
        } else {
          const url = this.addLanguagePrefixPipe.transform('/app/login');
          return this.router.navigateByUrl(url);
        }
      }),
      catchError(async (error: any) => {
        await this.router.navigateByUrl(this.addLanguagePrefixPipe.transform('/app/login'));
        return false;
      })
    );
  }
}
