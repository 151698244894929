<div class="vh-100 d-flex main-container aboutus-section">
  <div class="aboutus-section">
    <div class="container">
      <div class="aboutus">
        <div class="about-tr">
          <h2 class="aboutus-title">ПРАВИЛАТА И УСЛОВИТЕ ЗА КОРИСТЕЊЕ НА ПЛАТФОРМАТА METRICITY – ЗАПОЗНАЈ ГО СВОЈОТ
            КЛИЕНТ
          </h2>
          <div class="aboutus-text">
            <b>1. Дефиниции на поимите</b>
            <ul>
              <li>1.1. “Понудувач“ на платфoрмата Metricity – Запознај го својот клиент е друштвото Сајтех Доо Скопје,
                друштво за компјутерско програмирање, информатичка технологија и консултантски услуги, со седиште на ул.
                Митрополит Теодосиј Гологанов бр.54/1-4 Скопје – Центар, Центар со даночен број 4080017565255
                Понудувачот
                има својство на контролор на збирка на лични податоци според прописите за заштита на личните податоци.
              </li>
              <li>1.2. “Нарачател“е нарачателот на платформата Metricity – Запознај го својот клиент.</li>
              <li>1.3. Под “Организација” се подразбира првата регистрација која што Нарачателотправен субјект ја
                регистрирал во платформата. При регистрација на Организацијата, нарачателот креира и прв Корисник на
                Организацијата кој располага со сите права што произлегуваат од претплатата во платформата. Првиот
                корисник може да креира и други Корисници на Организацијата, кои ќе имаат пристап до профилот на
                Клиентските Профили и тој тоа го прави на своја волја и е целосно одговорен за истото.
              </li>
              <li>1.4. Под “Корисник на Организација“ се мисли на сите вработени или овластени лица на Нарачателот кои
                можат да пристапат во платформата. Овие лица ќе можат да се логираат и да ги користат сите
                функционалности
                во платформата. Организацијата исто така претставува Корисник, и сите Корисници се водат како ролја
                Менаџери на Организацијата.
              </li>
              <li>1.5. Под “Клиентски профил“ се подразбираат сите податоци на нарачателот. Овие податоци се внесени од
                страна на Корисниците на Организацијата и корисниците кои се овластени од него, во профилот или
                профилите
                кои се креирани од нивна страна.
              </li>
              <li>1.6. Права на интелектуална сопственост се оние права што се квалификувани како такви преку
                дефинициите
                дадени од различните правни системи ширум светот, без оглед дали се правни системи или дефиниции кои
                произлегуваат од системите на Заедничко право и затоа се правно признаени, а особено:
                <ul>
                  <li>- Заштитени права, патенти, трговски тајни, трговски тајни, трговски тајни, knowhow или know-how,
                    трговски марки, патенти, пронајдоци, дизајн, логоа, трговски фустан, морални права (т.е. авторско
                    право), маскирани зборови, пароли и други формули за рекламирање и деривати од која било форма, што
                    се
                    користат за професионални комерцијални цели и која било друга форма на приватни нематеријални права
                  </li>
                </ul>
              </li>
              <li>1.7. Податоци на нарачателот-клиентски профили се податоците кои нарачателот ги обезбедил со
                согласност
                на субјектот на лични податоци и тоа:
                - Име - Презиме
                - Татково име
                - ЕМБГ
                - Датум и место на раѓање
                - Држава и место на раѓање
                - Адреса на живеење
                - Лична карта број
                - Орган на издавање
                - Датум на важност
                - Држава лична карта
                - Пасош број
                - Држава пасош
                - Датум на важност
                - Адреса за контакт
                - Телефон - Емаил
                - Работен Статус
                - Работодавец
                - Адреса на работодавецот
                - Дејност на работодавецот
                - Продукт
                - Канал на дистрибуција
                Овие податоци нарачателот ги обезбедува согласно законот за спречување на перење пари и финансирање на
                тероризам, а врз основа на склучен Договор на нарачателот со понудувачот овие податоците се проследуваат
                во платформата Metricity – Запознај го својот клиент, било во рамките на чувањето податоци на
                нарачателот
                или во рамките на користењето на платформата Metricity – Запознај го својот клиент.
              </li>
              <li>Нарачателот ги чува податоците во платформата Metricity – Запознај го својот клиент врз основа на
                склучен договор со понудувачот. Чување на податоците на нарачателот подразбира чување на податоците од
                точка 1.7
              </li>
              <li>1.9. Претплата е цената на сервисите, која нарачателот врз основа на Договорот за Услуга ја плаќа на
                Понудувачот.
              </li>
            </ul>
            <b>2. Платформата Metricity – Запознај го својот клиент</b>, нуди софтверска апликација како услуга преку
            кој
            се преземаат мерките и дејствијата за спречување на перење пари и финансирање на тероризам усогласени со
            Законот за спречување перење пари и финансирање на тероризам. Овие мерки и дејствија Организацијата ги
            применува врз своите Клиенти Профили.
            <ul>
              <li>2.1. Преку оваа платформа Нарачателот може: <br>
                - Да изврши проверка дали Клиентиските Профили се наоѓаат
                на: Консолидирана Листа на САД, Консолидирана Листа на ЕУ и Консолидирана Листа на Обединетите Нации.
                - Да изврши проверка на листи на Носители на Јавни Функции, Негативни Вести и Осудувани Лица - Да изврши
                проверка на листа на земји согласно ФАТФ – Оперативна група за определување на дејства за спречување на
                перење пари и финансирање тероризам - Да го идентификува Клиентскиот Профил, согласно член 16 од Законот
                за спречување на перење пари и финансирање на тероризам - Изготвување на проценка на ризикот од перење
                пари и финансирање на тероризам, согласно член 10 од Законот за Спречување на Перење Пари и Финансирање
                на
                Тероризам, а земајќи ги предвид факторите на ризик кои се однесуваат на:
                клиентот; • државите или географските подрачја; • производите, услугите или трансакциите и • каналите на
                дистрибуција.
              </li>
              <li>2.2. За цените корисниците треба да прочитат на веб страната во делот Цени - <a
                href="http://metricity.mk/price">http://metricity.mk/price</a> (
                + евентуални поврзани страни ).
              </li>
              <li>2.3. Софтвер како Сервис Услуга и Цените за користење ќе бидат регулирани во Договор за користење на
                софтверската платформа Metricity-Запознај го својот клиент (<b>“Услуга“</b>).
              </li>
            </ul>
            <p>
              <b>3. Минимум хардверски побарувања</b> за да ја користат платформата, Корисниците треба да имаат
              компјутер
              со интернет конекција. Корисниците се одговорни за брзината на нивната интернет врска, и се свесни дека за
              добро користење потребно е да имаат брза интернет конекција без прекини и солиден проток. Платформата ги
              поддржува сите стандардни интернет пребарувачи.
            </p>
            <b>4. Посебни одредби за личните податоци</b>
            <ul>
              <li>4.1. Нарачателот се согласува понудувачот да смее во рамките на Услугата, да ги собира и користи сите
                лични податоци кои ги добива од нарачателот врз основа на Договорот за Користење на Софтверската
                Платформа
                Metricity – Запознај го својот Клиент.
              </li>
              <li>4.2. Понудувачот на платформата Metricity-Запознај го својот клиент, нема увид во податоците кои
                нарачателот ги внесува, чува, односно обработува во платформата.
              </li>
              <li>4.3. Нарачателот му гарантира на понудувачот дека сите лични податоци кои му ги проследува се собрани
                согласно важечкото законодавство во Република Македонија за спречување на перење пари и финансирање на
                тероризам и дека лицата на кои се однесуваат овие податоци се информирани за обработката на податоците
                во
                платформата Metricity – Запознај го својот клиент
              </li>
              <li>4.4. Кога нарачателот или лицето на кое се однесуваат личните податоци сака да обезбеди остварување на
                правата на поединецот во врска со личните податоци во согласност со важечкото законодавство во Република
                Македонија за заштита на личните податоци, мора до понудувачот на платформата Metricity-Запознај го
                својот
                клиент, да поднесе соодветно барање, на кое понудувачот на платформата мора даму одговори во рок од 30
                (триесет) дена. Понудувачот на платформата MetricityЗапознај го својот клиент, не смее да ги одбие
                барањата од овој став, освен ако за тоа не постојат основани причини.
              </li>
            </ul>
            <b>5. Постапување со податоците на нарачателот</b>
            <ul>
              <li>5.1. Користењето на платформата и пристапот до податоците на нарачателот му се овозможуваат само со
                внес
                на корисничко име и лозинка која содржи барем 8 (осум) знаци, минимум 1 (една) голема буква и минимум 1
                (еден) специјален знак
              </li>
              <li>5.2. Понудувачот на платформата води системски дневници за употребата на платформата, црпењето извори
                и
                за пристапот до податоците на нарачателот. Во системските дневници автоматски се запишува називот на
                нарачателот, компјутерската деловна единица на пристапување, датум и час на пристап, видот на податоци
                на
                нарачателот до кои нарачателот пристапил, како и начините на обработка на податоците на нарачателот. Во
                системскиот дневник се запишува и секој обид за недозволен пристап до податоците на нарачателот или за
                користење на платформата.
              </li>
              <li>5.3. Платформата врши автоматизирана обработка на податоците на нарачателот, при што автоматизираната
                обработка со користење на функционалноста на платформата ја пушта самиот нарачател, а понудувачот на
                платформата Metricity-Запознај го својот клиент, нема никакво влијание. Видовите автоматизирана
                обработка
                на податоците на нарачателот кои ги врши платформата Metricity-Запознај го својот клиент, се следните:
                собирање, прибавување, упис, уредување, зачувување, прилагодување или изменување, увид, употреба,
                откривање со пренос, соопштување, ширење или друг начин на давање на располагање, класификација или
                поврзување, блокирање, анонимизирање, бришење или уништување.
              </li>
              <li>5.4. Нарачателот сноси исклучива одговорност дека податоците во рамките на Услугата преку платформата
                Metricity-Запознај го својот клиент, ги проследува на соодветна правна основа. Понудувачот на
                платформата
                Metricity-Запознај го својот клиент, во никој случај не му одговара на нарачателот или на трети лица,
                доколку нарачателот личните податоци, деловните тајни или друг вид податоци ги проследи неовластено или
                без соодветна правна основа или во спротивност со важечкото законодавство.
              </li>
              <li>5.5. Понудувачот на платформата Metricity-Запознај го својот клиент, на нарачателот му гарантира дека
                податоците на нарачателот во платформата се одвоени од податоците на другите нарачатели и други лица.
              </li>
              <li>5.6. По истекот на Договорот за Услуга, како и со претходна 30-дневна напомена од страна на
                Понудувачот,
                ќе бидат трајно и неповратно избришани сите податоци на нарачателот кои се чуваат во платформата,
                вклучувајќи ги и личните податоци и други податоци, како и евентуални резервни или други копии кои се
                наоѓаат во базата на податоци.
              </li>
            </ul>
            <b>6. Ажурирање на платформата Metricity-Запознај го својот клиент</b>
            <ul>
              <li>6.1. Понудувачот на платформата Metricity-Запознај го својот клиент, ќе ја ажурира платформата
                најмалку
                еднаш годишно.
              </li>
              <li>6.2. Доколку ажурирањето на платформата Metricity е отежнато или невозможно од која било причина од
                страна на нарачателот, понудувачот на платформата има право на повраток на трошоците кои ќе настанат од
                изведбата на ажурирањето.
              </li>
              <li>6.3. Ажурирањето врз основа на Договорот за услуги е вклучено во цената.</li>
            </ul>
            <b>7. Изјава за изземање од одговорност на понудувачот на платформата Metricity-Запознај го својот
              клиент</b>
            <ul>
              <li>7.1. Понудувачот на платформата Metricity-Запознај го својот клиент, неговите набавувачи и неговите
                договорувачи во никој случај, согласно минималните законски ограничувања за изземање на одговорноста, на
                нарачателот или на трети лица не одговараат за никаква штета нанесена на нарачателот или на трети лица
                од
                каква било промена или адаптација на платформата Metricity-Запознај го својот клиент, вклучувајќи ги и
                дополнителните апликации, која нарачателот или трето лице ја извршило врз основа на некој договор или
                неовластено. За изземање на двата вида штета од претходниот став вклучуваат, но не се ограничени на
                намалување на имотот (обична штета), спречување на зголемувањето на имотот (изгубена добивка),
                предизвикување телесна или душевна болка или страв, како и наштетување на угледот на правното лице
                (нематеријална штета).
              </li>
            </ul>
            <b>8. Последици од непочитување на одредбите</b>
            <ul>
              <li>8.1. Доколку нарачателот ги прекрши одредбите на Правилата и условите за користење на платформата
                Metricity-Запознај го својот клиент, понудувачот на платформата може на нарачателот веднаш да му го
                оневозможи користењето на платформата Metricity-Запознај го својот клиент.
              </li>
              <li>8.2. Понудувачот на платформата Metricity-Запознај го својот клиент, го задржува правото да контролира
                дали нарачателот ја користи платформата во согласност со одредбите од Правилата и условите за користење
                на
                платформата Metricity-Запознај го својот клиент.
              </li>
            </ul>
            <b>9. Завршни одредби</b>
            <ul>
              <li>9.1. Нарачателот е должен ажурно да одговори на повиците и напомените на понудувачот на платформата
                Metricity-Запознај го својот клиент.
              </li>
              <li>9.2. Нарачателот се обврзува уште 1 година по истекот на важноста на Договорот за услуги да ги чува
                деловните тајни поврзани со платформата Metricity-Запознај го својот клиент или со понудувачот на
                платформата Metricity-Запознај го својот клиент, како што е определено во Законот за трговски друштва,
                како и други доверливи информации до кои дошол во за време на важноста на Договорот за услуги.
              </li>
              <li>9.3. Нарачателот не смее правата и обврските од Правилата и условите за користење на платформата
                Metricity-Запознај го својот клиент, односно кое било одделно право од Правилата и условите за користење
                на платформата Metricity-Запознај го својот клиент, без писмено одобрение од понудувачот на платформата
                да
                ги пренесе на трети лица.
              </li>
              <li>9.4. Доколку која било одредба Правилата и условите за користење на платформата Metricity - Запознај
                го
                својот клиент се покаже како ништовна, тоа нема да влијае на важноста на другите одредби кои во целост
                остануваат во сила.
              </li>
              <li>9.5. Правилата и условите за користење на платформата Metricity - Запознај го својот клиент, ги
                претставуваат целокупните услови за користење на платформата Metricity - Запознај го својот клиент и ги
                заменуваат сите евентуални останати договори за предметот на користење на платформата Даталаб Пантеон,
                освен кога во Правилата и условите за користење на платформата Metricity - Запознај го својот клиент е
                опредлено дека се надополнети со правила на некој друг договор или документ.
              </li>
              <li>9.6. Во случај на спор во врска со која било одредба на Правилата и условите за користење на
                платформата
                Metricity-Запознај го својот клиент, надлежен е судот во Република Македонија.
              </li>
            </ul>
            <p><b>Важи од: 25.11.2019 г.</b></p>
          </div>
        </div>
        <design-button class="float-right"
                              [text]="'signup-terms-policy: Во ред' | translate"
                              (clicked)="discard()"></design-button>
      </div>
    </div>
  </div>
</div>
