import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-signup-privacy-policy',
  templateUrl: './signup-privacy-policy.component.html',
  styleUrls: ['./signup-privacy-policy.component.scss']
})
export class SignupPrivacyPolicyComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SignupPrivacyPolicyComponent>) {
  }

  ngOnInit() {
  }

  discard() {
    this.dialogRef.close();
  }

}
