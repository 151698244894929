import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FormGroup} from '@angular/forms';
import {markFormFieldsAsTouchedOnSubmit} from '@app/utils/forms.utils';

@Component({
  selector: 'design-button',
  templateUrl: './design-button.component.html',
  styleUrls: ['./design-button.component.scss']
})
export class DesignButtonComponent implements OnInit {

  @Input()
  running: boolean;

  @Input()
  disabled: boolean;

  @Input()
  text: string;

  @Input()
  tooltip: string;

  @Input()
  color: ThemePalette = 'primary';

  @Input()
  icon: IconProp;

  @Input()
  type: 'raised-button' | 'menu-item' | 'stroked-button' = 'raised-button';

  @Input()
  form: FormGroup;

  @Input()
  buttonType: string | undefined = undefined;

  @Output()
  clicked = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

  click() {
    if (this.form) {
      markFormFieldsAsTouchedOnSubmit(this.form);
    }

    if (this.clicked) {
      this.clicked.emit();
    }
  }

}
