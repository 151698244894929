import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {catchError, filter, switchMap, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthErrorInterceptorService implements HttpInterceptor {

  refreshingToken = false;
  refreshTokenSubject = new BehaviorSubject<string>(null);

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401) {
          throw error; // we intercept only authentication errors
        }
        if (req.url.includes('/auth/refreshToken')) { // if /auth/refreshToken also got 401, that means the user will have to login again
          this.authenticationService.logout();
          throw error; //logout will reload page anyway, so it doesn't really matter if we throw error or not
        }
        if (req.url.includes('/auth/login')) {
          throw error; // if a user tries to log in with wrong credentials, he should really get the 401 response, instead of sending request with refreshToken and logging out if it fails
        }
        if (this.refreshingToken) {
          return this.refreshTokenSubject.pipe(
            filter(it => it != null),
            take(1),
            switchMap((newAccessToken: string) => {
              return next.handle(this.addNewAccessTokenToRequest(req, newAccessToken));
            })
          );
        } else {
          this.refreshingToken = true;
          this.refreshTokenSubject.next(null);
          return this.authenticationService.refreshAccessToken().pipe(
            switchMap((response) => {
              this.refreshingToken = false;
              this.refreshTokenSubject.next(response.accessToken);
              return next.handle(this.addNewAccessTokenToRequest(req, response.accessToken));
            }),
            catchError(e => {
              this.refreshingToken = false;
              throw error;
            })
          );
        }
      })
    );
  }

  addNewAccessTokenToRequest(req: HttpRequest<any>, newAccessToken: string): HttpRequest<any> {
    if (!newAccessToken) {
      return req;
    }
    return req.clone({setHeaders: {Authorization: `Bearer ${newAccessToken}`}});
  }

}
