<link appMoveToHead *ngFor="let alternateLink of alternateLinks" rel="alternate" [hreflang]="alternateLink.lang" [href]="alternateLink.uri | safeLink" />

<meta appMoveToHead name="description" content="{{'index.html:description' | translate}}">

<meta appMoveToHead name="keywords" content="{{'index.html:keywords' | translate}}">

<meta appMoveToHead property="og:site_name" content="Metricity">
<meta appMoveToHead property="og:type" content="website" />
<meta appMoveToHead property="og:title" content="{{'index.html:title' | translate}}" />
<meta appMoveToHead property="og:description" content="{{'index.html:description' | translate}}" />
<meta appMoveToHead property="og:image" content="https://metricity.mk/assets/landing-page/home-bg.jpg" />
<meta appMoveToHead property="og:url" content="https://metricity.mk{{url}}" />

<!-- Twitter Summary Card meta tags -->
<meta appMoveToHead name="twitter:card" content="summary" />
<meta appMoveToHead name="twitter:title" content="{{'index.html:title' | translate}}" />
<meta appMoveToHead name="twitter:site" content="@Metricity.mk" />
<meta appMoveToHead name="twitter:creator" content="@Metricity.mk" />
<meta appMoveToHead name="twitter:description" content="{{'index.html:description' | translate}}" />
<meta appMoveToHead name="twitter:image" content="assets/login-background-optimized.jpeg" />

<title appMoveToHead>{{'index.html:title' | translate}}</title>

<router-outlet></router-outlet>
