<div class="swiper swiper-main">
  <swiper-container appSwiper #swiperRef [config]="config" init="false">
    <swiper-slide appSwiper *ngFor="let image of images" [ngStyle]="{'overflow': 'hidden'}" class="swiper-slide">
      <div class="lazy swiper-slide-inner"></div>
    </swiper-slide>
  </swiper-container>
  <div class="swiper-pagination"></div>
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
</div>
