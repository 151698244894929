import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  choice ;
  constructor() { }

  ngOnInit() {
    this.choice = 1;
  }

  setPost(id) {
    return this.choice = id;
  }

}
