import {BrowserModule, TransferState} from '@angular/platform-browser';
import {APP_ID, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {HttpAuthErrorInterceptorService} from './services/interceptors/http-auth-error-interceptor.service';
import {HttpJwtInterceptorService} from './services/interceptors/http-jwt-interceptor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RxUtilsService} from './services/rx-utils.service';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {SetPasswordComponent} from './components/set-password/set-password.component';
import {SignupComponent} from './components/signup/signup.component';
import {UserService} from '@app/services/user.service';
import {UserStateService} from '@app/states/user-state.service';
import {SharedModule} from '@app/shared/shared.module';
import {DesignModule} from '@app/design/design.module';
import {registerLocaleData} from '@angular/common';
import localeMk from '@angular/common/locales/mk';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {BlogComponent} from './landing-page/blog/blog.component';
import {ContactComponent} from './landing-page/contact/contact.component';
import {HomeComponent} from './landing-page/home/home.component';
import {HowItWorksComponent} from './landing-page/how-it-works/how-it-works.component';
import {InfoComponent} from './landing-page/info/info.component';
import {PriceComponent} from './landing-page/price/price.component';
import {FooterComponent} from './landing-page/footer/footer.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PrivacyPolicyComponent} from './landing-page/privacy-policy/privacy-policy.component';
import {TermsComponent} from './landing-page/terms/terms.component';
import {SignupPrivacyPolicyComponent} from './components/signup-privacy-policy/signup-privacy-policy.component';
import {SignupTermsPolicyComponent} from './components/signup-terms-policy/signup-terms-policy.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {LanguageService} from '@app/services/language.service';
import {environment} from '@env/environment';
import {
  HttpLanguageHeaderInterceptorService
} from '@app/services/interceptors/http-language-header-interceptor.service';
import {LocalStorageService} from '@app/services/local-storage.service';
import {translateBrowserLoaderFactory} from '@app/loaders/translate-browser.loader';
import {MoveToHeadDirective} from '@app/directives/move-to-head.directive';
import {LanguageRootComponent} from './components/language-root/language-root.component';
import {CountriesService} from "@app/services/countries.service";
import {CountriesStateService} from "@app/states/countries-state.service";

// Swiper
import {SwiperComponent} from "@app/shared/components/swiper/swiper.component";
import {SwiperDirective} from "./directives/swiper.directive";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SetPasswordComponent,
    SignupComponent,
    BlogComponent,
    ContactComponent,
    HomeComponent,
    HowItWorksComponent,
    InfoComponent,
    PriceComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    SignupPrivacyPolicyComponent,
    SignupTermsPolicyComponent,
    MoveToHeadDirective,
    LanguageRootComponent,
    SwiperComponent,
    SwiperDirective
  ],
  imports: [
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DesignModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    })
  ],
  providers: [
    [{ provide: APP_ID, useValue: 'serverApp' }],
    {provide: HTTP_INTERCEPTORS, useClass: HttpAuthErrorInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpJwtInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpLanguageHeaderInterceptorService, multi: true},
    {provide: LOCALE_ID, useValue: environment.defaultLanguage},
    LanguageService,
    LocalStorageService,
    RxUtilsService,
    UserService,
    UserStateService,
    CountriesService,
    CountriesStateService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeMk, 'mk');
    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeEn, 'en');
  }
}
