
import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {NavigationEnd, Router} from "@angular/router";
import {environment} from "@env/environment";

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private router: Router) {

  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const gaMeasurementId = environment.GA_MEASUREMENT_ID;
      if (gaMeasurementId != null) {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            gtag('config', gaMeasurementId, {'page_path': event.urlAfterRedirects});
          }
        });
      }
    }


  }
}
