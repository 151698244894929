import {Pipe, PipeTransform} from '@angular/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'customerType'
})
export class CustomerTypePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value.toUpperCase() === 'PERSON') {
      return marker('customer-type.pipe:Физичко лице');
    }
    if (value.toUpperCase() === 'ORGANISATION') {
      return marker('customer-type.pipe:Правно лице');
    }
    return value;
  }

}
