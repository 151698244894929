import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'design-input-text',
  templateUrl: './design-input-text.component.html',
  styleUrls: ['./design-input-text.component.scss']
})
export class DesignInputTextComponent implements OnInit {

  @Input()
  placeholder: string;

  @Input()
  name: string;

  @Input()
  form: FormGroup;

  @Input()
  type: 'text' | 'email' | 'password' | 'textarea' | 'number' = 'text';

  @Input()
  year: number | undefined = undefined;

  @Input()
  customClasses = '';

  @Input()
  suffix: string;

  constructor() {
  }

  ngOnInit() {
  }

}
