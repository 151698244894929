import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from "@angular/router";
import {LocalStorageService} from "@app/services/local-storage.service";
import {environment} from "@env/environment";
import {first} from "rxjs/operators";

@Injectable()
export class LanguageService {


  private currentLanguage: string;
  public languageTranslations = {
    mk: 'Македонски',
    en: 'English',
    de: 'Deutsch'
  };

  constructor(private translate: TranslateService,
              private router: Router,
              private localStorageService: LocalStorageService) {

    this.currentLanguage = this.localStorageService.getLanguageCode() || environment.defaultLanguage;
  }

  setLanguageFromUrl(pathLanguage: string): void {
    this.currentLanguage = pathLanguage;
    this.localStorageService.setLanguageCode(pathLanguage);
    this.translate.use(pathLanguage).pipe(first()).subscribe();
  }

  getCurrentLanguage(): string {
    return this.currentLanguage;
  }

  changeLanguage(language: string) {
    if (language !== this.currentLanguage) {
      window.location.href = this.router.url.replace(`/${this.currentLanguage}/`, `/${language}/`);
    }
  }
}
