<div class="d-flex align-items-center justify-content-between">
  <div>
    <span>{{message}}</span>
    <div *ngIf="listData">
      <ul>
        <li *ngFor="let item of listData">{{item}}</li>
      </ul>
    </div>
  </div>
  <div class="ml-2">
    <button mat-raised-button *ngIf="routerAction" [routerLink]="routerLink">{{routerAction}}</button>
    <button mat-icon-button (click)="close()">х</button>
  </div>
</div>
