<div class="main-container">
    <app-header></app-header>
    <div class="pricing pricing-palden mt-5">
      <div class="pricing-item">
          <div class="pricing-deco">
              <svg class="pricing-deco-img" enable-background="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" xml:space="preserve" y="0px">
                  <path class="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
    c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"></path>
                  <path class="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
    c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"></path>
                  <path class="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
    H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"></path>
                  <path class="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
    c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"></path>
              </svg>
              <div class="pricing-price"><span class="pricing-currency">&#8364;</span>99
                  <span class="pricing-period"> {{'price:месечно'| translate}}</span>
              </div>
<!--              <h3 class="pricing-title">{{'price:Основен'| translate}}</h3>-->
          </div>
<!--          <ul class="pricing-feature-list">-->
<!--              <li class="pricing-feature mb-5">50 {{'price:профили'| translate}}</li>-->
<!--          </ul>-->
          <a routerLink="../app/sign-up" class="pricing-action">{{'price:Продолжи'| translate}}</a>
      </div>
      <div class="pricing-item mx-4">
          <div class="pricing-deco">
              <svg class="pricing-deco-img" enable-background="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" xml:space="preserve" y="0px">
                  <path class="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
    c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"></path>
                  <path class="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
    c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"></path>
                  <path class="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
    H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"></path>
                  <path class="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
    c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"></path>
              </svg>
              <div class="pricing-price"><span class="pricing-currency">&#8364;</span>3
                  <span class="pricing-period">/ {{'price:клиент'| translate}}</span>
              </div>
<!--              <h3 class="pricing-title">{{'price:Медиум'| translate}}</h3>-->
          </div>
<!--          <ul class="pricing-feature-list">-->
<!--              <li class="pricing-feature">200 {{'price:профили'| translate}}</li>-->
<!--          </ul>-->
          <a routerLink="../app/sign-up" class="pricing-action">{{'price:Продолжи'| translate}}</a>
      </div>
      <div class="pricing-item">
          <div class="pricing-deco">
              <svg class="pricing-deco-img" enable-background="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" xml:space="preserve" y="0px">
                  <path class="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
    c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"></path>
                  <path class="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
    c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"></path>
                  <path class="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
    H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"></path>
                  <path class="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
    c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"></path>
              </svg>
              <div class="pricing-price"><span class="pricing-currency">&#8364;</span>0.015
                  <span class="pricing-period">/ {{'price:Screening проверка'| translate}}</span>
              </div>
<!--              <h3 class="pricing-title">{{'price:Премиум'| translate}}</h3>-->
          </div>
<!--          <ul class="pricing-feature-list">-->
<!--              <li class="pricing-feature">600 {{'price:профили'| translate}}</li>-->
<!--          </ul>-->
          <a routerLink="../app/sign-up" class="pricing-action">{{'price:Продолжи'| translate}}</a>
      </div>
    </div>
    <div>
      <p class="muted vat-info">* Цените се без вклучен ДДВ</p>
    </div>
    <app-footer></app-footer>
</div>
