import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

@Component({
    selector: 'shared-set-password-form',
    templateUrl: './set-password-form.component.html',
    styleUrls: ['./set-password-form.component.scss']
})
export class SetPasswordFormComponent implements OnInit {

    @Input()
    form: FormGroup;

    @Input()
    passwordControlName: string;

    @Input()
    passwordPlaceholder: string;

    @Input()
    confirmPasswordControlName: string;

    @Input()
    confirmPasswordPlaceholder: string;

    confirmValidParentMatcher = new ConfirmValidParentMatcher();

    constructor() {
    }

    ngOnInit(): void {
    }

}

class ConfirmValidParentMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.parent.hasError('notMatchingPasswords') && control.touched;
    }
}
