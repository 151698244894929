<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" [routerLink]="['/home' | addLanguagePrefix]">
    <img width="150px" class="img-brand ml-5" src="/assets/logo3.png" alt="Metricity">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
          aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse mr-3" id="navbarToggler">
    <ul class="navbar-nav mx-auto mr-2 mt-lg-0 text-center">
      <li class="nav-item">
        <a [routerLink]="['/home' | addLanguagePrefix]" class="nav-link" routerLinkActive="active"><b>{{'header:Дома'| translate}}</b></a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/how-it-works' | addLanguagePrefix]" class="nav-link" routerLinkActive="active"><b>{{'header:Како работи'| translate}}</b></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/price' | addLanguagePrefix]" routerLinkActive="active"><b>{{'header:Цени'| translate}}</b></a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/blog' | addLanguagePrefix]" class="nav-link" routerLinkActive="active"><b>{{'header:Блог'| translate}}</b></a>
      </li>
      <!-- <li class="nav-item">
        <a [routerLink]="['/about' | addLanguagePrefix]" class="nav-link" routerLinkActive="active">За Нас</a>
      </li> -->
      <li class="nav-item">
        <a [routerLink]="['/info' | addLanguagePrefix]" class="nav-link" routerLinkActive="active"><b>{{'header:АМЛ инфо'| translate}}</b></a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/contact' | addLanguagePrefix]" class="nav-link" routerLinkActive="active"><b>{{'header:Контакт'| translate}}</b></a>
      </li>
    </ul>
    <shared-language class="text-center mr-3"></shared-language>
    <ng-container>
      <div [ngClass]="{'hidden': !showLoginActionButtons}" class="text-center">
        <design-button  class="mr-2"
                        color=""
                        [routerLink]="['/app/login' | addLanguagePrefix]"
                        text="{{'header:Најави се'| translate}}"
        ></design-button>
      </div>
      <div [ngClass]="{'hidden': !showLoginActionButtons}" class="text-center">
        <design-button  color="primary"
                        [routerLink]="['/app/sign-up' | addLanguagePrefix]"
                        text="{{'header:Регистрирај се'| translate}}"
        ></design-button>
      </div>
    </ng-container>
  </div>
</nav>
