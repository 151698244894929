import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AuthenticationService} from '@app/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationComponent} from '@app/shared/components/notification/notification.component';
import {TranslateService} from '@ngx-translate/core';
import {AddLanguagePrefixPipe} from "@app/shared/pipes/add-language-prefix.pipe";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  returnUrl: string;
  loginButtonLoader = false;
  registerButtonLoader = false;

  displayError = false;

  displayTFStep = false;

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private addLanguagePrefixPipe: AddLanguagePrefixPipe) {
    this.form = new FormGroup({
      email: new FormControl(null),
      password: new FormControl(null),
      tfaCode: new FormControl(null)
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  ngOnInit() {
  }

  login() {
    this.loginButtonLoader = true;
    this.displayError = false;
    this.authenticationService.login(this.form.value['email'], this.form.value['password'], this.form.value['tfaCode'])
      .subscribe((res) => {
        if (res.status === 206) {
          this.displayTFStep = true;
        } else {
          if (res.body.role === 'ADMIN') {
            this.router.navigateByUrl((this.returnUrl && this.returnUrl.substr(3).startsWith('/app/admin') ? this.returnUrl : null) || this.addLanguagePrefixPipe.transform(`/app/admin`));
          }
          if (res.body.role === 'MANAGER') {
            this.router.navigateByUrl((this.returnUrl && this.returnUrl.substr(3).startsWith('/app/client') ? this.returnUrl : null) || this.addLanguagePrefixPipe.transform(`/app/client`));
          }
        }
        this.loginButtonLoader = false;
      }, (error) => {
        this.snackBar.openFromComponent(NotificationComponent, {
          data: {
            message: error.error && error.error.message ? error.error.message : this.translate.instant('login:Грешни податоци')
          },
          panelClass: 'danger-snackbar',
          duration: 5000
        });
        this.displayError = true;
        this.loginButtonLoader = false;
      });
  }

  goStepBack() {
    this.displayTFStep = false;
  }

  goToSignUp(): void {
    this.router.navigate(['../sign-up'], {relativeTo: this.activatedRoute});
  }
}
