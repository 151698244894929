import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '@app/services/authentication.service';
import {Observable, throwError} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserStateService} from '@app/states/user-state.service';
import {catchError, filter, finalize} from 'rxjs/operators';
import {User} from 'metricity-shared/src/interfaces/models/user';
import {NotificationComponent} from '@app/shared/components/notification/notification.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AddLanguagePrefixPipe} from "@app/shared/pipes/add-language-prefix.pipe";

@Component({
  selector: 'shared-tfa-setup',
  templateUrl: './tfa-setup.component.html',
  styleUrls: ['./tfa-setup.component.scss']
})
export class TfaSetupComponent implements OnInit {

  tfaSetupDataObservable: Observable<{ message: string, dataUrl: string, tempSecret: string }>;
  formGroup: FormGroup;

  tfaEnabledUserObservable: Observable<User>;
  success = false;

  verifying = false;

  @Input()
  asChildComponent = false;
  recoveryCode: string;

  constructor(private authenticationService: AuthenticationService,
              private userStateService: UserStateService,
              private router: Router,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private addLanguagePrefixPipe: AddLanguagePrefixPipe) {
    this.formGroup = new FormGroup(({
      tfaCode: new FormControl('', [Validators.required])
    }));
    this.tfaEnabledUserObservable = this.userStateService.getUser().pipe(
      filter(user => user.tfaEnabled)
    );
  }

  ngOnInit() {
    this.tfaSetup();
  }

  tfaSetup() {
    this.tfaSetupDataObservable = this.authenticationService.tfaSetup().pipe(
      catchError(error => {
        if (!this.asChildComponent && error.status === 400) {
          this.router.navigateByUrl(this.addLanguagePrefixPipe.transform('/app/login'));
        }
        return throwError(error);
      })
    );
  }

  tfaVerify() {
    this.verifying = true;
    this.authenticationService.tfaVerify(this.formGroup.value.tfaCode).pipe(
      finalize(() => this.verifying = false)
    ).subscribe((it) => {
      this.recoveryCode = it.recoveryCode;
      this.userStateService.reload();
      this.success = true;
      this.snackBar.openFromComponent(NotificationComponent, {
        data: {
          message: this.translate.instant('tfa-setup:Успешно поставување на Two Factor автентикација')
        },
        panelClass: 'success-snackbar',
        duration: 5000
      });
    }, () => {
      this.snackBar.openFromComponent(NotificationComponent, {
        data: {
          message: this.translate.instant('tfa-setup:Внесовте погрешен код')
        },
        panelClass: 'danger-snackbar',
        duration: 5000
      });
    });
  }

  reloadPage() {
    this.router.navigateByUrl(this.addLanguagePrefixPipe.transform('/app/login'));
  }

  logout() {
    this.authenticationService.logout();
  }

}
