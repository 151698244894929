import {
  AfterViewInit,
  Component, ElementRef,
  Input, Renderer2, ViewChild
} from '@angular/core';
import {SwiperOptions} from "swiper/types/swiper-options";
import Swiper from "swiper";

@Component({
  selector: 'shared-swiper',
  templateUrl: './swiper.component.html',
  styleUrl: './swiper.component.scss'
})
export class SwiperComponent implements AfterViewInit {
  swiper?: Swiper;
  @ViewChild('swiperRef') swiperRef: ElementRef | undefined;

  @Input() images;
  index = 0;

  constructor(private _renderer: Renderer2) {
    window.addEventListener('scroll', this.lazyLoad.bind(this));
  }

  ngAfterViewInit() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
    setTimeout(() => {
      this.lazyLoad();
    }, 100);
  }

  // Swiper
  public config: SwiperOptions = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 3,
    loop: true,
    autoplay: false,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,

    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      1: {
        slidesPerView: 1
      },
      480: {
        slidesPerView: 1
      },
      767: {
        slidesPerView: 2
      },
      1024: {
        slidesPerView: 3
      }
    }
  }

  lazyLoad = () => {
    let lazyloadImages = document.querySelectorAll("div.lazy");
    if(lazyloadImages.length > 0) {
      this.lazyLoadCheck(lazyloadImages);
    }
  }

  lazyLoadCheck(lazyloadImages: NodeListOf<Element>) {
    lazyloadImages.forEach((element, eId) => {
      const rect = element.getBoundingClientRect();

      if (rect.top < window.innerHeight) {
        this._renderer.setStyle(element, 'background-image', `url("${this.images[eId]}")`);
        element.classList.remove('lazy');
      }
    });
  }
}
