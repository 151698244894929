<app-login-shared
  [imageUrl]="'assets/login-background-optimized.jpeg'"
  [displaySideImage]="true"
>
  <form slot="sideImage" class="login__form" [formGroup]="form" (ngSubmit)="login()">
    <div class="login__form-container bg-white transparent">
      <div class="login__form-container-text">
        <div>
          <button aria-label="Go back to login" mat-icon-button *ngIf="displayTFStep" class="back-button">
            <mat-icon (click)="goStepBack()" aria-label="Side nav toggle icon">keyboard_arrow_left</mat-icon>
          </button>
          <h3>{{!displayTFStep ? ('login:Најави се' | translate) : ('login:Внеси код за Two Factor автентикација' | translate)}}</h3>
        </div>
      </div>
      <ng-container *ngIf="!displayTFStep">
        <design-input-text [placeholder]="'login:Е-маил'| translate" name="email" type="email"
                           [form]="form"></design-input-text>
        <design-input-text [placeholder]="'login:Лозинка'| translate" name="password" type="password"
                           [form]="form"></design-input-text>
      </ng-container>
      <ng-container *ngIf="displayTFStep">
        <design-input-text [placeholder]="'123 456'" name="tfaCode"
                           [form]="form"></design-input-text>
        <span class="tfa-label">{{'login:Алтернативно, внесете го кодот за обновување' | translate}}</span>
      </ng-container>
      <shared-authentication-button
        [text]="'login:Најави се' | translate"
        [primaryColor]="'#00afef'"
        [secondaryColor]="'#0061a0'"
        [loading]="loginButtonLoader"
        (click)="login()"
      ></shared-authentication-button>
      <shared-authentication-button
        [text]="'login:Регистрирај се' | translate"
        [primaryColor]="'#80d7f7'"
        [secondaryColor]="'#00afef'"
        [loading]="registerButtonLoader"
        (click)="goToSignUp()"
      ></shared-authentication-button>
      <div *ngIf="!displayTFStep" class="row m-1">
        <div class="col-12 text-center">
          <a [routerLink]="'../forgot-password'">
            {{'login:Заборавена лозинка?'| translate}}
          </a>
        </div>
      </div>
    </div>
  </form>
</app-login-shared>
