import {Injectable} from '@angular/core';
import {merge, Observable, Subject} from 'rxjs';
import {User} from 'metricity-shared/src/interfaces/models/user';
import {UserService} from '@app/services/user.service';
import {RxUtilsService} from '@app/services/rx-utils.service';
import {LocalStorageService} from "@app/services/local-storage.service";
import {filter, map} from "rxjs/operators";

@Injectable()
export class UserStateService {

  private readonly userObservable: Observable<User>;
  private reload$ = new Subject<void>();

  constructor(private rxUtilsService: RxUtilsService,
              private userService: UserService,
              private localStorageService: LocalStorageService) {

    const initiator = merge(
      this.localStorageService.getAccessTokenObservable().pipe(filter(it => it != null)),
      this.reload$.asObservable()
    );
    this.userObservable = this.rxUtilsService.createGetStateValue(initiator, this.userService.getUser(),
      '', null);
    this.userObservable = merge(
      this.userObservable,
      this.localStorageService.getAccessTokenObservable().pipe(
        filter(it => it == null),
        map(it => null))
    ) // when localStorage.accessToken gets null, userObservable should emit null as well
  }

  getUser() {
    return this.userObservable;
  }

  reload() {
    this.reload$.next();
  }
}
