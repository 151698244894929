import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {User} from 'metricity-shared/src/interfaces/models/user';


@Injectable()
export class UserService {

  constructor(private http: HttpClient) {
  }

  changePassword(oldPassword: string, password: string, confirmPassword: string): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(environment.domain + '/user/change-password', {oldPassword, password, confirmPassword});
  }

  changeLocale(locale: string): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(environment.domain + '/user/change-locale', {locale});
  }

  getUser(): Observable<User> {
    return this.http.get<User>(environment.domain + '/user/');
  }

}
