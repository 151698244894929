import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'example-sanctions-screening-report',
    templateUrl: './example-report.component.html',
    styleUrls: ['./example-report.component.scss']
})
export class ExampleReportComponent implements OnInit {

    title: string;
    examples: { name: string, src: SafeResourceUrl }[];
    src: SafeResourceUrl;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer) {
        this.examples = data.examples.map(it => ({
            name: it.name,
            src: sanitizer.bypassSecurityTrustResourceUrl(`assets/${it.file}#toolbar=0`)
        }));
        this.title = data.title;
    }

    ngOnInit() {
    }

}
