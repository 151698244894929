import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '@app/services/authentication.service';
import {LanguageService} from '@app/services/language.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AddLanguagePrefixPipe} from "@app/shared/pipes/add-language-prefix.pipe";
import {LocalStorageService} from "@app/services/local-storage.service";

@Component({
  selector: 'app-login-shared',
  templateUrl: './login-shared.component.html',
  styleUrls: ['./login-shared.component.scss']
})
export class LoginSharedComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() showSpinner;
  @Input() displaySideImage;

  displayLoader = true;

  constructor(private authenticationService: AuthenticationService,
              private localStorageService: LocalStorageService,
              private languageService: LanguageService,
              private dialog: MatDialog,
              private router: Router,
              private addLanguagePrefixPipe: AddLanguagePrefixPipe) {
  }

  ngOnInit(): void {
    const token = this.localStorageService.getAccessToken();
    if (token) {
      this.authenticationService.getRoleFromToken(token).subscribe((result) => {
        if (result.role === 'ADMIN') {
          this.router.navigateByUrl(this.addLanguagePrefixPipe.transform(`/app/admin`));
        }
        if (result.role === 'MANAGER') {
          this.router.navigateByUrl(this.addLanguagePrefixPipe.transform(`/app/client`));
        }
      }, (err) => this.displayLoader = false);
    } else {
      this.displayLoader = false;
    }
  }

}
