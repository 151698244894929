import {Pipe, PipeTransform} from '@angular/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'riskValue'
})
export class RiskValuePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === 'LOW') {
      return marker('risk-value.pipe:Низок');
    }
    if (value === 'MEDIUM') {
      return marker('risk-value.pipe:Среден');
    }
    if (value === 'HIGH') {
      return marker('risk-value.pipe:Висок');
    }
    if (value == null) {
      return marker('risk-value.pipe:Нема пресметан ризик');
    }
    return value;
  }

}
