export const environment = {
  production: true,
  displayDummyWorldPep: false,
  domain: 'https://metricity.mk/api',
  cPayCallbackOkUrl: 'https://metricity.mk/api/cpay/success',
  cPayCallbackFailUrl: 'https://metricity.mk/api/cpay/fail',
  GA_MEASUREMENT_ID: 'UA-132448662-3',
  defaultLanguage: 'mk',
  availableLanguages: [
    {code: 'mk', name: 'Macedonian'},
    {code: 'en', name: 'English'}
  ],
  overrideAmount: false,
  cPayEnabled: true
};
