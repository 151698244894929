import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'shared-authentication-button',
  templateUrl: './authentication-button.component.html',
  styleUrls: ['./authentication-button.component.scss']
})
export class AuthenticationButtonComponent implements OnInit {

  @Input() primaryColor: string;
  @Input() secondaryColor: string;
  @Input() text: string;
  @Input() loading: boolean;
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
