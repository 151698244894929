import {NgModule} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule
} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {LayoutModule} from '@angular/cdk/layout';
import {DesignNavItemComponent} from './components/design-nav-item/design-nav-item.component';
import {RouterModule} from '@angular/router';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {FlexModule} from '@angular/flex-layout';
import {DesignBreadcrumbComponent} from './components/design-breadcrumb/design-breadcrumb.component';
import {CommonModule} from '@angular/common';
import {DesignInfoCardComponent} from './components/design-info-card/design-info-card.component';
import {DesignModalboxActionButtonComponent} from './components/design-modalbox-action-button/design-modalbox-action-button.component';
import {DesignButtonComponent} from './components/design-button/design-button.component';
import {TitleValueRowComponent} from './components/title-value-row/title-value-row.component';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule
} from '@angular/material-moment-adapter';
import {DesignLoaderButtonComponent} from './components/design-loader-button/design-loader-button.component';
import {DesignCloseDialogButtonComponent} from '@app/design/components/design-close-dialog-button/design-close-dialog-button.component';
import {DesignFileInputComponent} from '@app/design/components/design-file-input/design-file-input.component';
import {DesignStepperNextButtonComponent} from '@app/design/components/design-stepper-next-button/design-stepper-next-button.component';
import {DesignStepperPreviousButtonComponent} from '@app/design/components/design-stepper-previous-button/design-stepper-previous-button.component';
import {DesignInputTextComponent} from '@app/design/components/design-input-text/design-input-text.component';
import {DesignInputRadioComponent} from '@app/design/components/design-input-radio/design-input-radio.component';
import {DesignInputSelectComponent} from '@app/design/components/design-input-select/design-input-select.component';
import {DesignInputDatepickerComponent} from '@app/design/components/design-input-datepicker/design-input-datepicker.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { DesignCloseHeaderButtonComponent } from './components/design-close-header-button/design-close-header-button.component';
import {MomentUtcDateAdapter} from "@app/design/moment-utc-date.adapter";
import { DesignInputTextareaComponent } from './components/design-input-textarea/design-input-textarea.component';
import {DesignBurgerButtonComponent} from "@app/design/components/design-burger-button/design-burger-button.component";


@NgModule({
  declarations: [
    DesignNavItemComponent,
    DesignBreadcrumbComponent,
    DesignInfoCardComponent,
    DesignModalboxActionButtonComponent,
    DesignButtonComponent,
    TitleValueRowComponent,
    DesignLoaderButtonComponent,
    DesignCloseDialogButtonComponent,
    DesignFileInputComponent,
    DesignStepperNextButtonComponent,
    DesignStepperPreviousButtonComponent,
    DesignInputTextComponent,
    DesignInputDatepickerComponent,
    DesignInputSelectComponent,
    DesignInputRadioComponent,
    DesignCloseHeaderButtonComponent,
    DesignInputTextareaComponent,
    DesignBurgerButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule,
    MatButtonModule,
    MatInputModule,
    MatStepperModule,
    MatListModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatTableModule,
    MatSortModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatTabsModule,
    MatBadgeModule,
    MatMenuModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatSliderModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    FlexModule,
    FontAwesomeModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatProgressBarModule
  ],
  exports: [
    LayoutModule,
    MatButtonModule,
    MatInputModule,
    MatStepperModule,
    MatListModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatTableModule,
    MatSortModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatTabsModule,
    MatBadgeModule,
    MatMenuModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatSliderModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    DesignNavItemComponent,
    DesignBreadcrumbComponent,
    DesignInfoCardComponent,
    DesignModalboxActionButtonComponent,
    DesignButtonComponent,
    TitleValueRowComponent,
    MatMomentDateModule,
    DesignLoaderButtonComponent,
    DesignCloseDialogButtonComponent,
    DesignFileInputComponent,
    DesignStepperNextButtonComponent,
    DesignStepperPreviousButtonComponent,
    DesignInputTextComponent,
    DesignInputDatepickerComponent,
    DesignInputSelectComponent,
    DesignInputRadioComponent,
    MatProgressBarModule,
    DesignCloseHeaderButtonComponent,
    DesignInputTextareaComponent,
    DesignBurgerButtonComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'mk' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
  ]
})
export class DesignModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
