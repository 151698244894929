import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  choice ;
  constructor() { }

  ngOnInit() {
    this.choice = 1;
  }

  setPost(id) {
    return this.choice = id;
  }

}
