import {Pipe, PipeTransform} from '@angular/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'veriffSessionStatus'
})
export class VeriffSessionStatusPipe implements PipeTransform {
  
  transform(value: any, args?: any): any {
    switch (value) {
      case 'CREATED':
        return marker('veriff-session-status.pipe:Креирано барање');
      case 'STARTED':
        return marker('veriff-session-status.pipe:Започнато');
      case 'SUBMITTED':
        return marker('veriff-session-status.pipe:Доставени информации');
      case 'APPROVED':
        return marker('veriff-session-status.pipe:Одобрено од Veriff');
      case 'DECLINED':
        return marker('veriff-session-status.pipe:Одбиено од Veriff');
      case 'RESUBMISSION_REQUESTED':
        return marker('veriff-session-status.pipe:Побарано повторно верифицирање');
      case 'EXPIRED':
        return marker('veriff-session-status.pipe:Истечена сесија');
      case 'ABANDONED':
        return marker('veriff-session-status.pipe:Напуштена сесија');
      default:
        return value;
    }
  }

}
