<ng-container [formGroup]="form">
  <mat-form-field class="w-100">
    <mat-label>{{passwordPlaceholder}}</mat-label>
    <input matInput readonly onfocus="this.removeAttribute('readonly');"  type="password" formControlName="{{passwordControlName}}">
    <mat-error *ngIf="form.controls[passwordControlName].hasError('required')">
      {{'set-password:Новата лозинка не може да е празна'| translate}}
    </mat-error>
    <mat-error *ngIf="form.controls[passwordControlName].hasError('pattern')">
      {{'set-password:Лозинката мора да биде:'| translate}}
      <ul>
        <li>{{'set-password:8 карактери'| translate}}</li>
        <li>{{'set-password:Барем 1 мала буква'| translate}}</li>
        <li>{{'set-password:Барем 1 голема буква'| translate}}</li>
        <li>{{'set-password:Барем 1 бројка'| translate}}</li>
        <li>{{'set-password:Барем 1 специјален карактер'| translate}}</li>
      </ul>
    </mat-error>
  </mat-form-field>
  <mat-form-field class="w-100"
                  [ngClass]="{'margin-top-70': form.controls[passwordControlName].dirty && form.controls[passwordControlName].touched && form.controls[passwordControlName].hasError('pattern')}">
    <mat-label>{{confirmPasswordPlaceholder}}</mat-label>
    <input matInput readonly onfocus="this.removeAttribute('readonly');" type="password" formControlName="{{confirmPasswordControlName}}"
           [errorStateMatcher]="confirmValidParentMatcher">
    <mat-error>
      {{'set-password:Лозинките не се совпаѓаат'| translate}}
    </mat-error>
  </mat-form-field>
</ng-container>
