import {Component, Inject, OnInit} from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'shared-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  message: string;
  listData: string;
  routerAction: string;
  routerLink: string;

  constructor(private snackBarRef: MatSnackBarRef<NotificationComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

  ngOnInit() {
    this.message = this.data.message;
    this.listData = this.data.listData;
    this.routerAction = this.data.routerAction;
    this.routerLink = this.data.routerLink;
  }

  close() {
    this.snackBarRef.dismiss();
  }

}
