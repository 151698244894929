<app-header></app-header>
  <div class="container how-it-works">
    <div class="row">
      <div class="col-md-12">
        <p style="background: rgb(243, 243, 243);border-radius: 30px">{{'how-it-works:Се регистрирате на www.metricity.mk'| translate}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p style="background: rgb(243, 243, 243);border-radius: 30px">{{'how-it-works:Metricity автоматски ги подесува индикаторите и параметрите потребни за проценка на ризик на вашите клиенти'| translate}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-center">
        <div class="d-flex align-items-stretch mobile-stretch">
          <img class="my-5" src="/assets/landing-page/manual.png" width="150px" alt="">
          <p class="my-auto mobile-stretch" style="background: rgb(243, 243, 243);border-radius: 30px">{{'how-it-works:Ги внесувате вашите клиенти поединечно'| translate}}</p>

        </div>
      </div>
      <div class="col-md-6 text-center">
        <div class="d-flex align-items-stretch mobile-stretch">
          <img class="my-5" src="/assets/landing-page/excel.png" width="150px" alt="">
          <p class="my-auto mobile-stretch" style="background: rgb(243, 243, 243);border-radius: 30px">{{'how-it-works:Ги внесувате вашите клиенти преку Excel документ'| translate}}</p>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p style="background: rgb(243, 243, 243);border-radius: 30px">{{'how-it-works:Metricity редовно ги проверува вашите клиенти на „Санкциони листи“, одредува „ПЕП статус“ и врши „Проценка на ризик“'| translate}}</p>
      </div>
    </div>
    <div class="my-5">
      <h1 class="text-center">{{'how-it-works:Добивате'| translate}}</h1>
      <hr>
    </div>
    <div class="row">
      <div class="col-md-3">
        <h3>1</h3>
        <p>{{'how-it-works:Работна табла со целосен преглед на вашите клиенти'| translate}}</p>
      </div>
      <div class="col-md-3">
        <h3>2</h3>
        <p>{{'how-it-works:Детални PDF извештаи за секој ваш клиент'| translate}}</p>
      </div>
      <div class="col-md-3">
        <h3>3</h3>
        <p>{{'how-it-works:Нотификации за сите промени на статусот на вашите клиенти'| translate}}</p>
      </div>
      <div class="col-md-3">
        <h3>4</h3>
        <p>{{'how-it-works:Усогласеност со законот. Вашиот бизнис и углед се заштитени!'| translate}}</p>
      </div>
    </div>
  </div>
  <div class="bg-metricity">
    <div class="container">
      <div class="my-4">
        <h1 class="text-center">{{'how-it-works:Со Metricity заштедувате време и пари'| translate}}</h1>
        <hr class="with-metricity">
      </div>
      <div class="mt-5">
        <div class="row">
          <div class="col-md-3">
            <p>{{'how-it-works:Не треба повеќе да ја пишувате анализата во Word или да го пресметувате ризикот во Excel'| translate}}</p>
          </div>
          <div class="col-md-3">
            <p>{{'how-it-works:Не треба повеќе рачно да пребарувате на интернет низ санкциони листи или ембарго страни'| translate}}</p>
          </div>
          <div class="col-md-3">
            <p>{{'how-it-works:Не треба да се грижите како да направите свој ризик модел, Metricity го прави тоа за вас'| translate}}</p>
          </div>
          <div class="col-md-3">
            <p>{{'how-it-works:Плаќате месечно како трошок и не треба да се грижите за хостирање или одржување на системот'| translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="my-5">
      <h1 class="text-center">{{'how-it-works:Карактеристики'| translate}}</h1>
      <hr>
    </div>

    <div class="row">
      <div class="col-md-6">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Скрининг на мегународни листи'| translate}}
          </li>
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Автоматска проценка на ризик'| translate}}
          </li>
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Високо ризични јурисдикции'| translate}}
          </li>
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Политички експонирани лица (ПЕП)'| translate}}
          </li>
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Вистински сопственик'| translate}}
          </li>
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Засилена Анализа'| translate}}
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Централен документ систем'| translate}}
          </li>
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Логин страна за вашите клиенти'| translate}}
          </li>
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Верификација на клиент / Скенирање на ID документ'| translate}}
          </li>
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Креирање свои индикатори'| translate}}
          </li>
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Креирање свој модел на ризик'| translate}}
          </li>
          <li class="list-group-item">
            <fa-icon prefix="fa" icon="check"></fa-icon>{{'how-it-works:Писмен извештај од „Анализа на клиент“'| translate}}
          </li>
        </ul>
      </div>
    </div>
    <div class="my-5">
      <h1 class="text-center">{{'how-it-works:Поддршка'| translate}}</h1>
      <hr>
        <p>{{'how-it-works:Доколку имате прашања, коментар или ви е потребна техничка помош, ве молиме контактирајте нѐ на'| translate}} <a href="mailto: support@metricity.mk">support&#64;metricity.mk</a></p>
    </div>
  </div>
<app-footer></app-footer>
