<mat-toolbar color="primary" class="mat-primary-darkest" *ngIf="!asChildComponent">
  <div fxLayoutAlign="center center" class="w-100 h-100">
    <div class="div-logo-link h-75" [routerLink]="'/' | addLanguagePrefix">
      <img class="h-100" [src]="'/assets/logo3-small.png'" alt="Metricity">
    </div>
  </div>
</mat-toolbar>
<div *ngIf="!success" class="d-flex">
  <div *ngIf="tfaSetupDataObservable | async as tfaSetupData"
       [ngClass]="{'mx-auto': !asChildComponent, 'mt-5':!asChildComponent}">
    <form [formGroup]="formGroup" role="form">
      <div>
        <div>
          <ng-container *ngIf="!asChildComponent">
            <h2>{{'tfa-setup:Поставување на Two Factor автентикација' | translate}}</h2>
            <mat-divider></mat-divider>
          </ng-container>
          <h6 class="text-muted mt-2">{{'tfa-setup:Чекор 1' | translate}}</h6>
          <div>{{'tfa-setup:Симнете ја и инсталирајте ја' | translate}}
            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
               target="blank">Google Authenticator</a> {{'tfa-setup:или' | translate}}
            <a href="https://authy.com/download/" target="blank">Authy</a>
            {{'tfa-setup:апликацијата на вашиот мобилен телефон' | translate}}
          </div>
          <mat-divider class="mt-4 mb-4"></mat-divider>
          <h6 class="text-muted mt-2">{{'tfa-setup:Чекор 2' | translate}}</h6>
          <div>{{'tfa-setup:Отворете ја апликацијата за автентикација и скенирајте го QR кодот' | translate}}</div>
          <div>
            <img [src]="tfaSetupData.dataUrl">
          </div>
          <mat-divider class="mt-4 mb-4"></mat-divider>
          <h6 class="text-muted mt-2">{{'tfa-setup:Чекор 3' | translate}}</h6>
          <div>{{'tfa-setup:Внесете го кодот за да ја верифицирате постапката.' | translate}}</div>
          <mat-form-field>
            <mat-label>{{'tfa-setup:Код за верификација' | translate}}</mat-label>
            <input matInput formControlName="tfaCode">
          </mat-form-field>
        </div>
      </div>
      <div fxLayoutAlign="flex-end">
        <div *ngIf="!asChildComponent">
          <button class="mr-3" mat-raised-button color="white" type="button" (click)="logout()">{{'tfa-setup:Одјави се' | translate}}</button>
        </div>
        <div>
          <design-button
            text="{{'tfa-setup:Уклучи' | translate}}"
            buttonType='submit'
            [form]="formGroup"
            [running]="verifying"
            (clicked)="tfaVerify()"></design-button>
        </div>
      </div>
    </form>
  </div>
</div>
<div *ngIf="success" class="vh-100 d-flex">
  <div *ngIf="tfaEnabledUserObservable | async as user; else loading" class="m-auto">
    <p>{{'tfa-setup:Two-factor Автентикацијата е овозможена успешно. Вашиот код за обновување е:' | translate}}</p>
    <p>{{recoveryCode}}</p>
    <p>{{'tfa-setup:Препорачуваме да чувате печатена верзија на кодот за обновување на безбедно место' | translate}}</p>
    <span class="clickable" (click)="reloadPage()">{{'tfa-setup:Кликнете тука за да продолжите' | translate}}</span>
  </div>
  <ng-template #loading>
    <mat-spinner class="mx-auto"></mat-spinner>
  </ng-template>
</div>
