import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Organisation} from 'metricity-shared/src/interfaces/models/organisation';
import {Subscription} from 'rxjs';

export function getFormData(form: FormGroup, skipFields: string[] = []) {
  const formData = new FormData();
  Object.keys(form.value)
    .filter(key => skipFields.indexOf(key) === -1 && form.value[key])
    .forEach(key => formData.append(key, form.value[key]));
  return formData;
}

export function markFormFieldsAsTouchedOnSubmit(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({onlySelf: true});
    } else if (control instanceof FormGroup) {
      if (this) {
        this.markFormFieldsAsTouchedOnSubmit(control);
      }
    }
  });
}

export function setSSNAndTaxNumberValidatorsBasedOnResidency(basicForm: FormGroup, personForm: FormGroup | null, organisationForm: FormGroup | null, organisation: Organisation): Subscription {
  return basicForm.get('resident').valueChanges.subscribe(resident => {
    if (organisation.isSsnAndTaxNumberRequired) {
      const ssnControl = personForm ? personForm.get('ssn') : null;
      const taxNumberControl = organisationForm ? organisationForm?.get('taxNumber') : null;
      if (resident) {
        ssnControl?.setValidators([Validators.required, Validators.minLength(13), Validators.maxLength(13)]);
        taxNumberControl?.setValidators([Validators.required, Validators.minLength(13), Validators.maxLength(13)]);
      } else {
        ssnControl?.clearValidators();
        ssnControl?.setValidators([Validators.required]);
        taxNumberControl?.clearValidators();
        taxNumberControl?.setValidators([Validators.required]);
      }
      ssnControl?.markAsTouched();
      ssnControl?.updateValueAndValidity();
      taxNumberControl?.markAsTouched();
      taxNumberControl?.updateValueAndValidity();
    }
  });
}
