<div *ngIf="currentLanguage">
  <button mat-stroked-button [matMenuTriggerFor]="appMenu">
      {{currentLanguage | uppercase}}
      <mat-icon>language</mat-icon>
  </button>
  <mat-menu #appMenu="matMenu" xPosition="before">
    <button mat-menu-item *ngFor="let language of availableLanguages" (click)="setLanguage(language.code)" type="button">
      {{languageService.languageTranslations[language.code]}}
      <span *ngIf="currentLanguage === language.code" class="ml-3">
        <fa-icon icon="check"></fa-icon>
      </span>
    </button>
  </mat-menu>
</div>

