import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AdminGuard} from './guards/admin.guard';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {SetPasswordComponent} from './components/set-password/set-password.component';
import {SignupComponent} from './components/signup/signup.component';
import {ClientGuard} from './guards/client.guard';
import {HomeComponent} from './landing-page/home/home.component';
import {HowItWorksComponent} from './landing-page/how-it-works/how-it-works.component';
import {PriceComponent} from './landing-page/price/price.component';
import {BlogComponent} from './landing-page/blog/blog.component';
import {InfoComponent} from './landing-page/info/info.component';
import {ContactComponent} from './landing-page/contact/contact.component';
import {PrivacyPolicyComponent} from './landing-page/privacy-policy/privacy-policy.component';
import {TermsComponent} from './landing-page/terms/terms.component';
import {TfaGuard} from '@app/guards/tfa.guard';
import {TfaSetupComponent} from '@app/shared/components/tfa-setup/tfa-setup.component';
import {environment} from "@env/environment";
import {LanguageRootComponent} from "@app/components/language-root/language-root.component";
import {TranslateModule} from "@ngx-translate/core";

const language = environment.defaultLanguage;
const languageAwareRoutes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'how-it-works', component: HowItWorksComponent},
  {path: 'price', component: PriceComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'info', component: InfoComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'terms', component: TermsComponent},
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'app', children: [
      {path: '', pathMatch: 'full', redirectTo: `/${language}/app/login`},
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [TfaGuard, AdminGuard]
      },
      {
        path: 'client',
        loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
        canActivate: [TfaGuard, ClientGuard]
      },
      {
        path: 'customer',
        loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
      },
      {path: 'login', component: LoginComponent},
      {path: 'sign-up', component: SignupComponent},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'set-password', component: SetPasswordComponent},
      {path: 'tfa-setup', component: TfaSetupComponent},
      {path: '**', redirectTo: '/' + language + '/home'},
    ]
  },
];

const routes: Routes = [
  {path: '', redirectTo: `/${language}/home`, pathMatch: 'full'},
  {
    path: 'mk', component: LanguageRootComponent, children: [
      ...languageAwareRoutes,
      {path: '', redirectTo: `/mk/home`, pathMatch: 'full'},
      {path: '**', redirectTo: '/mk/home'},
    ]
  },
  {
    path: 'en',
    component: LanguageRootComponent,
    children: [
      ...languageAwareRoutes,
      {path: '', redirectTo: `/en/home`, pathMatch: 'full'},
      {path: '**',  redirectTo: '/en/home'},
    ]
  },
  {
    path: 'de',
    component: LanguageRootComponent,
    children: [
      ...languageAwareRoutes,
      {path: '', redirectTo: `/de/home`, pathMatch: 'full'},
      {path: '**',  redirectTo: '/de/home'},]
  },
  {path: '**', redirectTo: `/${language}/home`},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
