import {Component, Input, OnInit} from '@angular/core';
import {LanguageService} from '@app/services/language.service';
import {FormGroup} from '@angular/forms';
import {DefaultHighRiskJurisdiction} from 'metricity-shared/src/interfaces/models/default-high-risk-jurisdiction';
import {HighRiskJurisdictionCountry} from 'metricity-shared/src/interfaces/models/high-risk-jurisdiction-country';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CountriesStateService} from '@app/states/countries-state.service';

@Component({
  selector: 'shared-input-countries',
  templateUrl: './input-countries.component.html',
  styleUrls: ['./input-countries.component.scss'],
})

export class InputCountriesComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() valueProperty: 'id' | 'Name';
  @Input() name: string;
  @Input() label: string;
  @Input() multiple = false;
  @Input() customClasses = '';
  @Input() countriesToBeRemoved: DefaultHighRiskJurisdiction[] | HighRiskJurisdictionCountry[] | undefined = undefined;

  countries: Observable<{ value: string | number, label: string }[]>;
  currentLanguage: string;

  constructor(public languageService: LanguageService,
              private countriesStateService: CountriesStateService
  ) {
  }

  ngOnInit(): void {
    this.getCountries();
    this.currentLanguage = this.languageService.getCurrentLanguage();
  }


  getCountries() {
    this.countries = this.countriesStateService.getCountries().pipe(
      map(countries => {
        if (this.countriesToBeRemoved && this.countriesToBeRemoved.length) {
          return countries.filter(
            country => !this.countriesToBeRemoved.find(
              countryToRemove => country.id === countryToRemove.riskCountryId
            )
          );
        } else {
          return countries;
        }
      }),
      map(countries => {
        return countries
          .map(it => ({
            value: this.valueProperty === 'id' ? it.id : this.valueProperty === 'Name' ? it.country : null,
            label: it[`country${this.currentLanguage === 'en' ? 'En' : this.currentLanguage === 'de' ? 'De' : ''}`].toLowerCase()
              .replace(/(^|\s)\S/g, firstLetter => firstLetter.toUpperCase())
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
      })
    );
  }
}
