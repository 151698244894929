import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {UserStateService} from '@app/states/user-state.service';
import {AddLanguagePrefixPipe} from "@app/shared/pipes/add-language-prefix.pipe";

@Injectable({
  providedIn: 'root'
})
export class TfaGuard implements CanActivate {

  constructor(private userStateService: UserStateService, private router: Router,
              private addLanguagePrefixPipe: AddLanguagePrefixPipe) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userStateService.getUser().pipe(
      switchMap(async user => {
        if (!user) {
          return false;
        }
        if (user.redirectToTfaSetup && !user.tfaEnabled) {
          await this.router.navigateByUrl(this.addLanguagePrefixPipe.transform('/app/tfa-setup'));
          return false;
        }
        return true;
      })
    );
  }
}
