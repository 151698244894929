<app-header></app-header>
<div class="aboutus-section">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="bg-metricity">
          <div class="about-tr">
            <h5 class="aboutus-title text-white text-center">{{'info:АМЛ инфо'| translate}}</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <button class="btn btn-link w-100" (click)="setPost(1)">
                  {{'info:Мерки и дејствија кои ги преземат субјектите'| translate}}
                </button>
              </li>
              <li class="list-group-item">
                <button class="btn btn-link w-100" (click)="setPost(2)">
                  {{'info:Проценка на ризикот од ППФТ'| translate}}
                </button>
              </li>
              <li class="list-group-item">
                <button class="btn btn-link w-100" (click)="setPost(3)">
                  {{'info:Програма за ефикасно намалување и управување со идентификуваниот ризик од ППФТ'| translate}}
                </button>
              </li>
              <li class="list-group-item">
                <button class="btn btn-link w-100" (click)="setPost(4)">
                  {{'info:Овластено лице,негов заменик и/или оддел за спречување на ППФТ'| translate}}
                </button>
              </li>
              <li class="list-group-item">
                <button class="btn btn-link w-100" (click)="setPost(5)">
                  {{'info:Анализа на клиент'| translate}}
                </button>
              </li>
              <li class="list-group-item">
                <button class="btn btn-link w-100" (click)="setPost(6)">
                  {{'info:KYC Запознајте го својот клиент'| translate}}
                </button>
              </li>
              <li class="list-group-item">
                <button class="btn btn-link w-100" (click)="setPost(7)">
                  {{'info:Вистински сопственик'| translate}}
                </button>
              </li>
              <li class="list-group-item">
                <button class="btn btn-link w-100" (click)="setPost(8)">
                  {{'info:Носители на јавни функции'| translate}}
                </button>
              </li>
              <li class="list-group-item">
                <button class="btn btn-link w-100" (click)="setPost(9)">
                  {{'info:Фактори кои влијајат на нивото на ризик на клиентите'| translate}}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-9 col-sm-9 col-xs-12" [ngSwitch]="choice">
        <div class="aboutus">
          <div class="about-tr" *ngSwitchCase="1">
            <h2 class="aboutus-title">{{'info:Мерки и дејствија кои ги преземат субјектите'| translate}}</h2>
            <div class="aboutus-text">
              <ul>
                <li>{{'info:изготвување на проценка на ризикот од ППФТ и нејзино редовно ажурирање'| translate}}</li>
                <li>{{'info:воведување и примена на програми за ефикасно намалување и управување со идентификуваниoт ризик од ППФТ'| translate}}</li>
                <li>{{'info:именување на овластено лице и негов заменик и/или формирање на оддел за спречување на ППФТ'| translate}}</li>
                <li>{{'info:анализа на клиентите'| translate}}</li>
                <li>{{'info:известување и доставување на податоци, информации и документација до Управата за финансиско разузнавање'| translate}}</li>
                <li>{{'info:чување, заштита и водење евиденција на податоци'| translate}}</li>
                <li>{{'info:спроведување на внатрешна контрола и'| translate}}
                </li>
                <li>{{'info:други мерки кои произлегуваат од одредбите на овој закон'| translate}}</li>
              </ul>

            </div>
          </div>
          <div class="about-tr" *ngSwitchCase="2">
            <h2 class="aboutus-title">{{'info:Проценка на ризикот од ППФТ'| translate}}</h2>
            <div class="aboutus-text">
              <ul>
                <li>{{'info:Цел - идентификување, проценка и намалување на ризиците од ППФТ, земајќи ги предвид следните фактори:'| translate}}
                  <ul>
                    <li>{{'info:а) клиентот'| translate}}</li>
                    <li>{{'info:б) државите или географските подрачја'| translate}}</li>
                    <li>{{'info:в) производите, услугите или трансакциите и'| translate}}</li>
                    <li>{{'info:г) каналите на дистрибуција'| translate}}</li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>{{'info:Важно:'| translate}}
                  <ul>
                    <li>{{'info:Да биде документирана'| translate}}</li>
                    <li>{{'info:Да е усогласена со националната проценка на ризик'| translate}}</li>
                    <li>{{'info:Да соодветствува на големината, видот и дејноста на субјектот'| translate}}</li>
                    <li>{{'info:Редовно да се ажурира'| translate}}</li>
                  </ul>
                </li>
              </ul>

            </div>
          </div>
          <div class="about-tr" *ngSwitchCase="3">
            <h2 class="aboutus-title">{{'info:Програма за ефикасно намалување и управување со идентификуваниот ризик од ППФТ'| translate}}
            </h2>
            <div class="aboutus-text">
              <ul>
                <li>{{'info:цели, обем и начин на работа за спречување на ППФТ на субјектот'| translate}}</li>
                <li>{{'info:организациска структура на субјектот, позиција на овластеното лице/заменикот'| translate}}</li>
                <li>{{'info:податоци за овластеното лице и неговиот заменик'| translate}}</li>
                <li>{{'info:овластувања и одговорности на овластеното лице/заменик'| translate}}</li>
                <li>{{'info:овластувања и одговорности на сите вработени во субјектот поврзани со спречување на ППФТ'| translate}}</li>
                <li>{{'info:заклучоци од спроведената проценка на ризик и начин на управување со ризик од ППФТ'| translate}}</li>
                <li>{{'info:мерки на анализа на клиентот и прифатливост на клиенти'| translate}}</li>
                <li>{{'info:начин на препознавање на невообичаени трансакции и сомневање за ППФТ'| translate}}</li>
                <li>{{'info:начин на воспоставување на внатрешен механизам за навремено и адекватно известување'| translate}}</li>
                <li>{{'info:начин на чување на податоците и документите'| translate}}</li>
                <li>{{'info:начин на доставување на извештаи до Управата'| translate}}</li>
                <li>{{'info:план за постојана обука на вработените од областа на спречување на ППФТ - најмалку две обуки во текот на годината и'| translate}}</li>
                <li>{{'info:процедура и план на вршење на внатрешна контрола'| translate}}</li>
              </ul>

            </div>
          </div>
          <div class="about-tr" *ngSwitchCase="4">
            <h2 class="aboutus-title">{{'info:Овластено лице,негов заменик и/или оддел за спречување на ППФТ'| translate}}</h2>
            <div class="aboutus-text">
              <ul>
                <li>{{'info:Барања за овластените лица и вработените во одделот'| translate}}</li>
                <li>{{'info:Разграничување на деловните активности од активностите за СППФТ'| translate}}</li>
                <li>{{'info:Независност во одлуките'| translate}}</li>
                <li>{{'info:Непречен пристап до податоци и документи'| translate}}</li>
                <li>{{'info:Да бидат обучувани'| translate}}</li>
              </ul>

            </div>
          </div>
          <div class="about-tr" *ngSwitchCase="5">
            <h2 class="aboutus-title">{{'info:Анализа на клиент'| translate}}</h2>
            <div class="aboutus-text">
              <ul>
                <li>{{'info:воспоставување на деловен однос'| translate}}</li>
                <li>{{'info:во текот на деловниот однос'| translate}}</li>
                <li>{{'info:кога постои сомневање за вистинитоста или адекватноста на претходно добиените податоци за идентитетот на клиентот или вистинскиот сопственик и'| translate}}</li>
                <li>{{'info:кога постои сомневање за ППФТ, без оглед на каков било исклучок или износ на средства.'| translate}}</li>
              </ul>

            </div>
          </div>
          <div class="about-tr" *ngSwitchCase="6">
            <h2 class="aboutus-title">{{'info:KYC Запознајте го својот клиент'| translate}}</h2>
            <div class="aboutus-text">
              <ul>
                <li>{{'info:Што значи концептот Запознај го својот кллиент'| translate}}</li>
                <li>{{'info:Елементи'| translate}}
                  <ul>
                    <li>{{'info:Идентификација и верификација на клиентот'| translate}}</li>
                    <li>{{'info:Идентификација и верификација на ополномоштувачот'| translate}}</li>
                    <li>{{'info:Идентификација и потврдување на вистинскиот сопственик'| translate}}</li>
                    <li>{{'info:Утврдување на целта на деловниот однос'| translate}}</li>
                    <li>{{'info:Постојано следење на деловниот однос'| translate}}</li>
                    <li>{{'info:Верификација – потврдување на податоците од документи'| translate}}</li>
                    <li>{{'info:Препознавање на сомнително однесување'| translate}}</li>
                  </ul>
                </li>
                <li>{{'info:Обем на мерките – соодветен на утврдениот ризик за клиентот'| translate}}
                  <ul>
                    <li>{{'info:Поедноставена анализа'| translate}}</li>
                    <li>{{'info:Засилена анализа'| translate}}</li>
                  </ul>
                </li>
                <li>{{'info:Ажурирање на податоците на клиентите'| translate}}
                  <ul>
                    <li>{{'info:Низок ризик – 5 години'| translate}}</li>
                    <li>{{'info:Среден ризик – 3 години'| translate}}</li>
                    <li>{{'info:Висок ризик – 1 година'| translate}}</li>
                  </ul>
                </li>
              </ul>

            </div>
          </div>
          <div class="about-tr" *ngSwitchCase="7">
            <h2 class="aboutus-title">{{'info:Вистински сопственик'| translate}}</h2>
            <div class="aboutus-text">
              <ul>
                <li>{{'info:Физичко лице, кое е краен сопственик или индиректно влијание врз клиентот за чие име се врши трансакцијата, и/или има крајна и ефективна контрола над правно или физичко лице'| translate}}
                </li>
                <li>{{'info:Принципи на утврдување:'| translate}}
                  <ul>
                    <li>
                      {{'info:Директно или индиректно учество со најмалку 25% сопственички удели'| translate}}
                    </li>
                    <li>
                      {{'info:Остварување на контрола над управувањето'| translate}}
                    </li>
                  </ul>
                </li>
                <li>{{'info:Важно:'| translate}}
                  <ul>
                    <li>{{'info:Утврдување преку документи'| translate}}</li>
                    <li>{{'info:Документирање на добиената информација'| translate}}</li>
                    <li>{{'info:Предмет на ажурирање'| translate}}</li>
                  </ul>
                </li>
              </ul>

            </div>
          </div>
          <div class="about-tr" *ngSwitchCase="8">
            <h2 class="aboutus-title">{{'info:Носители на јавни функции'| translate}}</h2>
            <div class="aboutus-text">
              <ul>
                <li>{{'info:Носители на јавна функција се лица на кои им била доверена јавна функција'| translate}}</li>
                <li>{{'info:За носител на јавна функција се сметат лицата и одреден период по престанок на извршување на функцијата'| translate}}</li>
                <li>{{'info:Поимот вклучува и:'| translate}}
                  <ul>
                    <li>{{'info:Членови на семејството'| translate}}</li>
                    <li>{{'info:Лице кое се смета за близок соработник'| translate}}</li>
                  </ul>
                </li>
                <li>{{'info:Ризици кај носителите на јавни функции'| translate}}</li>
              </ul>

            </div>
          </div>
          <div class="about-tr" *ngSwitchCase="9">
            <h2 class="aboutus-title">{{'info:Фактори кои влијајат на нивото на ризик на клиентите'| translate}}</h2>
            <div class="aboutus-text">
              <ul>
                <li>{{'info:Лица на санкциони листи и листи за наблјудување'| translate}}</li>
                <li>{{'info:Лица од високорични земји (вклучетелно и off shore centers)'| translate}}</li>
                <li>{{'info:Одредени дејности'| translate}}</li>
                <li>{{'info:Вршење на трансакции кои отстапуваат од вообичаеното за клиентот'| translate}}</li>
                <li>{{'info:Компании школки (shell companies)'| translate}}</li>
                <li>{{'info:Индикатори за препознавање на сомнителни трансакции'| translate}}</li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
