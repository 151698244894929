<app-login-shared
  [imageUrl]="'assets/login-background-optimized.jpeg'"
  [displaySideImage]="true"
>
  <form slot="sideImage" class="login__form" [formGroup]="form" (ngSubmit)="form.valid && forgotPassword()">
    <div class="login__form-container bg-white transparent">
      <div *ngIf="!success" class="w-100 m-0">
        <div>
          {{'forgot-password:Внесете го вашиот е-маил за да ви испратиме линк за ресетирање'| translate}}
          <design-input-text [placeholder]="'login:Е-маил'| translate" name="email" type="email" [form]="form"></design-input-text>
          <small class="d-flex justify-content-center text-danger" *ngIf="error">
            {{'forgot-password:Внесениот е-маил е невалиден'| translate}}
          </small>
          <shared-authentication-button
            [text]="'forgot-password:Испрати' | translate"
            [primaryColor]="'#00afef'"
            [secondaryColor]="'#0061a0'"
            [loading]="sending | async"
            (click)="forgotPassword()"
          ></shared-authentication-button>
          <shared-authentication-button
            [text]="'login:Најави се' | translate"
            [primaryColor]="'#80d7f7'"
            [secondaryColor]="'#00afef'"
            [routerLink]="'../login'"
          ></shared-authentication-button>
        </div>
      </div>
      <div *ngIf="success" class="w-100 m-0 login__form-container__password-message">
        <div>{{'forgot-password:Е-маил со линк за ресетирање е испратен.'| translate}}</div>
        <shared-authentication-button
          [text]="'login:Најави се' | translate"
          [primaryColor]="'#00afef'"
          [secondaryColor]="'#0061a0'"
          (click)="goToLogin()"
        ></shared-authentication-button>
      </div>
    </div>
  </form>
</app-login-shared>

