import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthenticationService} from '@app/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  sending = new BehaviorSubject(false);
  error = false;
  form = this.fb.group({
    email: [null, [Validators.required, Validators.email]]
  });
  success = false;

  constructor(private authenticationService: AuthenticationService, private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
  }

  forgotPassword() {
    this.sending.next(true);
    this.authenticationService.forgotPassword(this.form.value['email']).subscribe((result) => {
      this.error = !result.success;
      this.success = result.success;
      this.sending.next(false);
    }, () => {
      this.error = true;
      this.sending.next(false);
    });
  }

  goToLogin() {
    this.router.navigate(['../login'], {relativeTo: this.activatedRoute});
  }

}
