<app-login-shared
  [imageUrl]="'assets/login-background-optimized.jpeg'"
  [displaySideImage]="true"
  [showSpinner]="showSpinner"
>
  <form slot="sideImage" class="login__form" [formGroup]="form" (ngSubmit)="setPassword()">
    <div class="login__form-container bg-white transparent">
      <div *ngIf="!success" class="row w-100 m-0">
        <div class="login__form-container-set-pwd">

          <h3>{{'set-password:Внесете нова лозинка'| translate}}</h3>

          <shared-set-password-form [form]="form" passwordControlName="password"
                                    [passwordPlaceholder]="'set-password:Лозинка'| translate"
                                    confirmPasswordControlName="confirmPassword"
                                    [confirmPasswordPlaceholder]="'set-password:Потврди лозинка'| translate"></shared-set-password-form>
          <div class="mt-1" *ngIf="requiredAcceptedTermsAndPrivacyPolicies">
            <mat-checkbox formControlName="acceptedTermsAndPrivacyPolicies"></mat-checkbox>
            {{'set-password:Ги прифаќам'| translate}} <span class="clickable"
                                                            (click)="showTermsDialog()">{{'set-password:правилата на користење'| translate}}</span> {{'set-password:и'| translate}}
            <span
              class="clickable"
              (click)="showPrivacyDialog()">{{'set-password:политиката на приватност'| translate}}</span>
          </div>
          <shared-authentication-button
            [text]="'set-password:Промени лозинка' | translate"
            [primaryColor]="'#00afef'"
            [secondaryColor]="'#0061a0'"
            [loading]="changingPassword$ | async"
            [disabled]="!form.valid || (requiredAcceptedTermsAndPrivacyPolicies && !form.get('acceptedTermsAndPrivacyPolicies').value)"
            (click)="setPassword()"
          ></shared-authentication-button>
          <shared-authentication-button
            [text]="'set-password:Најави се' | translate"
            [primaryColor]="'#80d7f7'"
            [secondaryColor]="'#00afef'"
            [routerLink]="'../login'"
          ></shared-authentication-button>
        </div>
      </div>
      <div *ngIf="success">
        <div>
          <div>{{'set-password:Вашата лозинка е успешно променета.'| translate}}</div>
          <shared-authentication-button
            [text]="'login:Најави се' | translate"
            [primaryColor]="'#00afef'"
            [secondaryColor]="'#0061a0'"
            (click)="goToLogin()"
          ></shared-authentication-button>
        </div>
      </div>
    </div>
  </form>
</app-login-shared>
