import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {RiskCountry} from 'metricity-shared/src/interfaces/models/risk-country';

@Injectable()
export class CountriesService {

  constructor(private http: HttpClient) {}

  getCountries() {
    return this.http.get<RiskCountry[]>(environment.domain + '/countries');
  }
}
