import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {BehaviorSubject} from "rxjs";


class LocalStorage implements Storage {
  [name: string]: any;
  readonly length: number;
  clear(): void {}
  getItem(key: string): string | null {return undefined;}
  key(index: number): string | null {return undefined;}
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}


@Injectable()
export class LocalStorageService {

  private storage: Storage;

  private accessToken$: BehaviorSubject<string>;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.storage = isPlatformBrowser(platformId) ? localStorage : new LocalStorage();
    this.accessToken$ = new BehaviorSubject<string>(this.getAccessToken());
  }

  [name: string]: any;

  length: number;

  private clear(): void {
    this.storage.clear();
  }

  private getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  private key(index: number): string | null {
    return this.storage.key(index);
  }

  private removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  private setItem(key: string, value: string): void {
    return this.storage.setItem(key, value);
  }

  setLanguageCode(value: string) {
    this.setItem('languageCode', value);
  }

  getLanguageCode() {
    return this.getItem('languageCode');
  }

  setRefreshToken(value: string) {
    this.setItem('refreshToken', value);
  }

  getRefreshToken() {
    return this.getItem('refreshToken');
  }

  removeRefreshToken() {
    this.removeItem('refreshToken')
  }

  setAccessToken(value: string) {
    this.setItem('accessToken', value);
    this.accessToken$.next(value);
  }

  getAccessToken() {
    return this.getItem('accessToken');
  }

  removeAccessToken() {
    this.removeItem('accessToken');
    this.accessToken$.next(null);
  }

  getAccessTokenObservable() {
    return this.accessToken$.asObservable();
  }

  getSidenavMode(): 'stay' | 'hover' {
    return this.storage.getItem('sidenavMode') as 'stay' | 'hover';
  }

  setSidenavMode(value: 'stay' | 'hover') {
    this.setItem('sidenavMode', value);
  }
}
